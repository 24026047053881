  
.section-main-content-buy{
     
   height: 100%;
 
}
 

section {
  transition: opacity 1s var(--mindai-section-bezier),transform 1s var(--mindai-section-bezier);
}


 


.main-overview.image.container{
  height: 100%;
  max-height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
 
}

.main-overview.image{
  height: auto;
  position: relative;
  background-color: white;
 

}





 

/* --------------------------------------- */



/* Grid Overview */
/* -------------------- */
.main-overview-presale-grid{
  display: grid;
  grid-template: "main aside" 1fr/9fr minmax(380px,1fr);
  grid-gap: 0 48px;
  -webkit-padding-end: 48px;
  padding-inline-end: 48px;
  scroll-margin-top: 52px;
  transition: none;
  scrollbar-width: none;
}


.gr-layout{
  height: 100%;
}

.images_assets{
  grid-area: main;
  max-height: 100%;
  position: -webkit-sticky;  
  position: sticky;
  overflow: hidden;
  height: 100vh;
  top: 0;
}


img,svg{
  transform: translateZ(0);
 
}


.images_{
  opacity: 0;
  visibility: hidden;
  transform: translateX(-24px);
  transition: opacity .5s var(--mindai-section-bezier),visibility .5s .5s,transform .5s var(--mindai-section-bezier);
 
}
 
.images_{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.images_.isactive{
  opacity: 1;
  visibility: inherit;
  transform: translateX(0);
  transition: opacity .5s var(--mindai-section-bezier),visibility .5s 0s,transform .5s var(--mindai-section-bezier);
  height: auto;
}

.images-exterior{
  width: 100%; 
  height: auto;
}
 
.images_.isactive .images_assets--section.is-active{
  transform: translateX(0); 
}

.images_.isactive .images_assets--section.is-active {
  transform: translateX(-24px);
}

.images_assets--section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
    align-items: center;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    margin: auto;
}



.images_assets--section.is-active {
  opacity: 1;
  visibility: inherit;
  -webkit-animation: asset-intro 5s ease 0s!important;
  animation:asset-intro 5s ease 0s!important;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  transition: opacity .5s var(--mindai-section-bezier);
}


.asset-loader__svg-container{ 
  background-color: transparent;
  stroke: none;
  fill: none;
  opacity:0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

.group--main-content--asset {
  width: 100%;
  height: 100%;
}
.asset-loader__ {
  transition: opacity 1s var(--mindai-section-bezier),transform 1s var(--mindai-section-bezier);
  image-rendering: -webkit-optimize-contrast;
}
@keyframes asset-intro{
  100%{
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}


.main-overview-presale-under-grid.image.container{
  margin-right: 75%;
  width: 75%; 
  height: auto;
  position: absolute;
  background-color: #fefefe;
  
}

.main-overview-presale-under-grid.image{
  
    height: auto;
    background-color: #fefefe;
    object-fit: cover;
    object-position: bottom;

 
}

.main-overview-presale-under-grid-description{ 
  width: 100%;  
  font-family: var(--mindai-base-font-family-combination);  
  position: relative;  
  text-align: center!important;
  height: auto;
  display: block;
  padding-bottom: 16px;
   
}



.main-overview-presale-under-grid-progress-bar-container{
  block-size: 70px;
  position: relative;
  display: block;
}

.main-overview-presale-under-grid-progress-bar{
  width: 100%;  
  font-family: var(--mindai-base-font-family-combination); 
  text-align: center; 
  block-size: auto;
  border-radius: 4px;
  background-color:  hsla(0,0%,58.8%,0.1);
  position: relative;  
  display: flex; 
  border-width: 3px; 
  padding: 0px 0px ;
  justify-content: center;
  align-items: center;
  gap: 4px; 
  left: 0;
  right: 0;
  bottom: 0;
  margin:auto;
}

.main-overview-presale-under-grid-progress-bar-content{
  overflow: hidden; 
  cursor: pointer;
  box-shadow: 0px 0px 10px -5px;
  border-radius: 4px; 
  position: relative;
  margin: auto;   
  padding: 0  ; 
  display: flex;  
  height: 28px;
  width: 100%;
  background-color:   hsla(0,0%,58.8%,0.1);
  justify-content: initial;
  justify-items: initial;
  align-items: initial;
}
 
.allocation-resume {
  width: 100%;  
  font-family: var(--mindai-base-font-family-combination); 
  text-align: center; 
  block-size: 28px;
  border-radius: 4px;
  background-color: hsla(0,0%,58.8%,0.1);
  position: relative; 
  max-width: 100%;
  max-height: 100%;  
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%; 
  border-width: 3px; 
  padding: 0px 0px ;
  justify-content: center;
  align-items: center;
  gap: 1px;
 
   
}

.loading-div{
  height: auto;
  width: 40vh;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-top: 60px;

}

._loading-container{
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.allocation-resume-item{
  overflow: hidden; 
  cursor: pointer;
  box-shadow: 0px 0px 10px -5px;
  border-radius: 4px; 
  position: relative;
  margin: auto;  
  justify-content: center; 
  background-color: #fff;
  padding: 0; 
  display: flex;
  height: 100%;
  text-align: center;
  width: 97% ;

}
.allocation-resume-item.blue{
  -webkit-animation: allocation-resume-item-blue-animation 2.5s ease 0s!important;
  animation:allocation-resume-item-blue-animation 2.5s infinite ease 0s!important ;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.allocation-resume-item.light_blue{
  -webkit-animation: allocation-resume-item-light_blue-animation 3s ease 0s!important;
  animation:allocation-resume-item-light_blue-animation 3s infinite ease 0s!important ;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.allocation-resume-item.light_green{
  -webkit-animation: allocation-resume-item-light_green-animation 3.5s ease 0s!important;
  animation:allocation-resume-item-light_green-animation 3.5s infinite ease 0s!important ;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.allocation-resume-item.yellow{
  -webkit-animation: allocation-resume-item-yellow-animation 4s ease 0s!important;
  animation:allocation-resume-item-yellow-animation 4s infinite ease 0s!important ;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode:both;
  animation-fill-mode: both;
}
.allocation-resume-item.magenta{
  -webkit-animation: allocation-resume-item-magenta-animation 4.5s ease 0s!important;
  animation:allocation-resume-item-magenta-animation 4.5s infinite ease 0s!important ;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@keyframes allocation-resume-item-blue-animation{
  0%{
    box-shadow: 0px 0px 10px -5px;
  }
  100%{
    box-shadow: #1c58d1 0px 0px 10px -5px;
  }
}
@keyframes allocation-resume-item-light_blue-animation{
  0%{
    box-shadow: 0px 0px 10px -5px;
  }
  100%{
    box-shadow: #00aaff 0px 0px 10px -5px;
  }
}
@keyframes allocation-resume-item-light_green-animation{
  0%{
    box-shadow: 0px 0px 10px -5px;
  }
  100%{
    box-shadow: #00e878 0px 0px 10px -5px;
  }
}
@keyframes allocation-resume-item-magenta-animation{
  0%{
    box-shadow: 0px 0px 10px -5px;
  }
  100%{
    box-shadow: #ff00c3 0px 0px 10px -5px;
  }
}
@keyframes allocation-resume-item-yellow-animation{
  0%{
    box-shadow: 0px 0px 10px -5px;
  }
  100%{
    box-shadow: #00f92a 0px 0px 10px -5px;
  }
}

.span-text{
  font-size: 16px;
  font-family: var(--mindai-base-font-family-combination);
  left: 0;
  right: 0;
  top: 0;  
  bottom: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center; 
  position: relative;
}
.progress{ 
  background-color: #fff;   
  border-radius: 4px  ;  
  margin-left: 0%;
}
@media (max-width: 1025px){
  body.no-scroll-cfg {
      overflow: hidden;
  }
}
.main-overview-presale-under-grid-description-presale-estimated-end{
  width: 100%;  
  font-family: var(--mindai-base-font-family-combination); 
  text-align: center; 
  height: auto;  
  position: relative;  
}
.main-overview-presale-under-grid.description.type-buyer{
  width: 80%;  
  font-family: var(--mindai-base-font-family-combination);
  font-size: 20px;
  text-align: center; 
  block-size: var(--mindai-section-size--5x); 
  border-radius: 4px;
  background-color: hsla(0,0%,58.8%,0.1);
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  object-position: center;
  left: 0;
  right: 0; 
  bottom: 0;
  margin: auto; 
  margin-top: var(--mindai-section-size--2x);
}
.main-overview-presale.description.wallet-connect{
  width: 95%;  
  font-family: var(--mindai-base-font-family-combination);
  font-size: 20px;
  text-align: center;
  margin-top: 3%;
  height: 45px;
  /* border-radius: 28px; */
  border-radius: 4px;
  background-color: #65d1d1ef;
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
 
  overflow: auto;
}

.main-overview-presale__description__wallet-connect{
  width: 90%;  
  font-family: var(--mindai-base-font-family-combination);
  font-size: 20px;
  text-align: center;
  margin-top: 3%;
  height: 45px;
  /* border-radius: 28px; */
  border-radius: 4px;
  background-color: #65d1d1ef;
  position: relative;
  display: flex; 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto; 
  overflow: auto;
}


.main-overview-presale-under-grid.description.allocation-data{
  width: 90%;  
  font-family: var(--mindai-base-font-family-combination);
  font-size: 20px;
  text-align: center;
  margin-top: 15%;
  height: 25px;

}
.main-overview-presale-under-grid.description.pro-user{
  width: 100%;  
  font-family: var(--mindai-base-font-family-combination);
  font-size: 20px;
  text-align: center; 
  height: auto;
  position: relative;
}

.main-overview-presale-under-grid.description.wallet-connect{
  /* width: 80%;   
  font-size: 20px;
  text-align: center; 
  height: 45px;
  padding: 24px;
  border-radius: 4px;
  background-color: #65d1d1ef;
  position: relative; 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;   */

  --background-color: transparent;
  --box-shadow--focus: inset 0 0 0 transparent;
  --box-shadow--highlight: 0 0 0 transparent;
  align-items: center;
  height: 80px;
  width: 100%;
  border-radius: 4px;
  display: inline-flex;
  font-family: var(--mindai-base-font-family-combination);
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  line-height: 1.2;
  padding:  24px;
  position: relative;
  color: #5c5e62;
  background-color: transparent;
  scroll-snap-align: center; 
  cursor: pointer;
  transition: background-color .3s ease,box-shadow .3s ease,color .3s ease;
}

.main-overview-presale-under-grid.description.a-button{
  /* width: 80%;   
  font-size: 20px;
  text-align: center; 
  height: 45px;
  padding: 24px;
  border-radius: 4px;
  background-color: #65d1d1ef;
  position: relative; 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;   */

  --background-color: transparent;
  --box-shadow--focus: inset 0 0 0 transparent;
  --box-shadow--highlight: 0 0 0 transparent;
  align-items: center;
  height: 80px;
  width: 100%;
  border-radius: 4px;
  display: inline-flex;
  font-family: var(--mindai-base-font-family-combination);
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  line-height: 1.2;
  padding:  24px;
  position: relative;
  color: #5c5e62;
  background-color: transparent;
  scroll-snap-align: center; 
  cursor: pointer;
  transition: background-color .3s ease,box-shadow .3s ease,color .3s ease;
}


/* ------------------------------ */

/* Global */
/* ------------------------------- */

.presale-header{
  font-family: var(--mindai-base-font-family-combination);
  font-size: 40px;
  text-align: center; 
  position: relative; 
  line-height: 0.8;
  -webkit-margin-end: auto;
  margin-inline-end: auto;
  -webkit-margin-start: auto;
  margin-inline-start: auto;
  text-align: center!important;
}

/* .presale-header-presale-estimated{
  font-family: var(--mindai-base-font-family-combination);
  font-size: 20px;
  text-align: center;
 
  position: fixed;
 

} */

.estimated-span{
  color: var(--mindai-section-theme-foreground-low-contrast);
}
 

.provider-span{
  font-size: 35px;
  font-family: var(--mindai-base-font-family-combination);
  text-align: justify;
  font-weight: bold; 
  font-style: red;
}
.mindai-layout-hero__header{
  --mindai-section-theme-foreground: var(--mindai-section-color-black);
  --mindai-section-theme-foreground--light: var(--mindai-section-color-black); 
  width: 100%; 
  line-height: 1em;
  padding: 0 24px;
  width: 100%;
  font-size: 35px;
  text-align: justify;
  color: var(--mindai-section-color-black);   
  /* font-weight: bold;  */
  transition: font .5s var(--mindai-section-bezier),color .5s var(--mindai-section-bezier),opacity 0s .5s, padding .33s var(--mindai-section-bezier);
}

.type-tab{
  /* width: auto; */
  /* border-radius: 27px; */
  /* border-radius: 4px;
  cursor: pointer;
  height: 55px;
  margin-top: 3px;
  margin-left: 3px;
  margin-right: 3px;
  white-space: nowrap;
  text-align: center;
  background-color: transparent;
  border: none; */
    --background-color: transparent;
    --box-shadow--focus: inset 0 0 0 transparent;
    --box-shadow--highlight: 0 0 0 transparent;
    align-items: center;
    block-size: calc(40 - 8px);
    border-radius: 4px;
    display: inline-flex;
    font-family: var(--mindai-base-font-family-combination);
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
    line-height: 1.2;
    padding: 4px 24px;
    position: relative;
    color: #5c5e62;
    background-color:  0 0 0 0 transparent;
    scroll-snap-align: center;
    box-shadow: inset 0 0 0 transparent, 0 4px  8px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;
    transition: background-color .3s ease,box-shadow .3s ease,color .3s ease;
}

.type-tab.white {
  background-color: white;
  border-color: none;
  border-width: 0;
  border-style: solid;
  
}

.function-btn{
  width: 100%;
  border-radius: var(--mindai-section-size--half);
  cursor: pointer;
  height: var(--mindai-section-size--5x);
  font-family: var(--mindai-base-font-family-combination);
  white-space: nowrap;
  text-align: center; 
  border-width: 2px;
  border-style: solid;
  color: black;
  background-color: var(--mindai-section-theme-foreground--light); 
  font-size: 12px;
  font-weight:800;
  
  transition:  border-color 1s ease, background-color 1.5s ease,color .33s ease;
}



.function-btn:hover{
  border-radius: 4px;
  background-color: var(--mindai-section-color-black);
  color: white;
  font-size: 12px;
  font-weight: 800;
}
.mindai-section-share-social-media-container{
  display: flex;
  justify-content: space-between;
  max-width: none;
  width: fit-content;
  margin: 0 auto;
  background: #393c41;
  border-radius: 16px;
  padding: 10px 0; 
  border: 2px solid #6fb2ff; 
}

.mindai-section-twitter-button-custom{
  display: grid;
  grid-template-columns: auto auto;
  /* background-color: #939aff; */
  background-image: linear-gradient(to right, #FFE000 0%, #799F0C 51%, #6cc4ff 100%);
  font-family: var(--mindai-base-font-family-combination);
  color: var(--mindai-section-color--white);
  inline-size: 80px;
  block-size: 3.5vh;
  position: relative;
  border-radius: 24px;
  border: 2px solid #30363d; 
  transition: border-color 1s ease,background-color .55s ease,color .33s ease,box-shadow .25s ease;
}
.mindai-section-twitter-button-custom:hover{
  background-image: linear-gradient(to right, #c4c4c4 0%, #799F0C 51%, #6cc4ff 100%);
  color: var(--mindai-section-button--background-color);
 /*border-color: rgb(0, 0, 0); */
}
.mindai-section-twitter-button-icon{ 
  width: fit-content; 
  position: relative;
  display: flex;
  border-radius: var(--mindai-section-size--2x); 
  /* background-color: #939aff; */
}
.mindai-section-twitter-button-text{  
  position: relative; 
  border-radius: var(--mindai-section-size--2x);
  border-style: solid 2px;
  border-color: #393c41;
  /* background-color: #939aff;  */
  text-align: left;
}
 


.main-overview-presale-under-grid.group-section{ 
  -webkit-padding-before: 28px;
  padding-block-start: 28px;
  -webkit-padding-after: 28px;
  padding-block-end: 28px;
  padding-bottom: 28px;
}

.modal-type-wallet-connection{
  block-size: 70%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  inline-size: 980px;
  left: 0;
  position: fixed;
  right: 0;
  background-color:#fff;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 0px 110px -60px  rgb(89, 89, 224);
  color: #393c39;
  max-block-size:  100%;
  overflow-y: auto;
  padding: 0;
  z-index: 1000;
  overscroll-behavior: contain;
} 

.mindai-exchange-dialog-container{
  transition: opacity .2s ease-in;
  z-index: 1005;
  opacity: 0;
  position: fixed;
  flex-flow: initial;
}

.mindai-layout-modal-type-wallet-connection{
  block-size: 90%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-inline-size: 1060px;
  inline-size: auto;
  left: 0;
  position: fixed;
  right: 0;
  background-color:#fff;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 0px 110px -60px  rgb(89, 89, 224);
  color: #393c39;
  max-block-size:  100%;
  overflow-y: auto;
  padding: 0;
  z-index: 1000;
  overscroll-behavior: contain;
  
}
.mindai-layout-modal-type-wallet-type-connection{
  block-size: 90%;
  max-block-size: none;
  inline-size: auto;
  max-inline-size: 1080px;
}
@media (max-width: 600px){
  .main-overview-presale-under-grid.description.wallet-connect {
    width: 85%; 
  }
  .main-overview-presale-under-grid.description.a-button{
    width: 100%
  }
  .function-btn {
    width: 85%; 
  }
  .main-overview-presale-under-grid-progress-bar-container{
    padding-inline-start: var(--mindai-section-size--2x);
    padding-inline-end: var(--mindai-section-size--2x);
  } 
  .span-text {
    font-size: 13px;
  }
  .mindai-layout-hero__header{
    font-size: var(--mindai-section-size--4x);
    padding: 0;
  }
 
}

@media (min-width: 600px) and (max-width: 899px){
  .main-overview-presale-under-grid-progress-bar-container{
    padding-inline-start: var(--mindai-section-size--8x);
    padding-inline-end: var(--mindai-section-size--8x);
  }
  .main-overview-presale-under-grid.description.wallet-connect {
    width: 20%; 
  }
  .main-overview-presale-under-grid.description.wallet-connect {
    width: 85%; 
  }
  .function-btn {
    width: 60%; 
  }
}
@media (min-width: 900px) and (max-width: 1024px){
  .main-overview-presale-under-grid-progress-bar-container{
    padding-inline-start: var(--mindai-section-size--12x);
    padding-inline-end: var(--mindai-section-size--12x);
  }
  .main-overview-presale-under-grid.description.type-buyer {
    width: 48%;
  }
  .main-overview-presale-under-grid.description.wallet-connect {
    width: 20%;
  }
  .main-overview-presale-under-grid.description.wallet-connect {
    width: 85%; 
  }
  .function-btn {
    width: 85%; 
  }
}
@media (min-width: 600px){
  .modal-type-wallet-connection[open] {
      transform: translateX(0) translateY(50%);
     
  }
  .modal-type-wallet-connection:not([open]) {
    transform: translateX(0) translateY(100%);
  }
  .mindai-layout-modal-type-wallet-connection[open] {
    transform: translateX(0) translateY(50%);
  }

 
  .mindai-layout-modal-type-wallet-connection:not([open]) {
    transform: translateX(0) translateY(100%);
  }
  .mindai-section-modal {
    --mindai-section-modal-border-radius: var(--mindai-section-size--2x);
    bottom: 50%;
    margin: auto;
    max-inline-size: calc(100vw - var(--mindai-section--12x));
    min-block-size: var(--mindai-section--8x);
    max-block-size: calc(100% - var(--mindai-section--12x));
    border-radius: 16px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
  }
}


.modal-type-wallet-connection:not([open]) {
  visibility: hidden;
}
.mindai-layout-modal-type-wallet-connection:not([open]) {
  visibility: hidden;
}
.mindai-layout-modal-type-wallet-connection:not([open]) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity .5s var(--mindai-section-bezier),transform .5s var(--mindai-section-bezier),visibility 0s .5s;
}
.modal-type-wallet-connection:not([open]) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity .5s var(--mindai-section-bezier),transform .5s var(--mindai-section-bezier),visibility 0s .5s;
}
.mindai-layout-modal-type-wallet-connection[open] {
  opacity: 1;
  transform: translateX(0) translateY(0);
  pointer-events: inherit;
  visibility: inherit; 
  transition: opacity .5s var(--mindai-section-bezier),transform .5s var(--mindai-section-bezier),visibility 0s 0s;
}
.modal-type-wallet-connection[open] {
  opacity: 1;
  transform: translateX(0) translateY(0);
  pointer-events: inherit;
  visibility: inherit;
  transition: opacity .9s var(--mindai-section-bezier),transform .9s var(--mindai-section-bezier),visibility 0s 0s;
}
.mindai-exchange-dialog-container.is-open{
  opacity: 1;
}
.mindai-exchange-dialog-container.is-close{
  opacity: 1;
}

.mindai-section-modal[open]{
  opacity: 1;
  transform: translateX(0) translateY(0);
  pointer-events: inherit;
  visibility: inherit;
  transition: opacity .5s var(--mindai-section-bezier),transform .5s var(--mindai-section-bezier),visibility 0s 0s;
}

 

.mindai-section-modal{
  --mindai-section-modal-border-radius: var(--mindai-section-size--1x);
  block-size: 100vh;
  bottom: 0;
  display: flex;
  flex-direction: column;
  inline-size: 100vw;
  left: 0;
  position: fixed;
  right: 0;
  background-color: none;
  border: none;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 -8px  16px 0 rgba(0, 0, 0, 0.16);
  color: var(--mindai-section-theme-foreground);
  max-block-size: calc(100% - var(--mindai-section-size--3x));
  overflow-y: auto;
  padding: 0;
  z-index: 1000;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}


.mindai-section-content-group {
  display: flex;
  flex-direction: column;
}
.mindai-section-content-groupComponent--container {
  padding: var(--mindai-section-size--6x) var(--mindai-section-size--4x) var(--mindai-section-size--10x) var(--mindai-section-size--4x);
  max-height: calc(100vh - 48px -24px);
  overflow-y: auto;
}
.mindai-section-modal--panels{
  display: flex;
  -ms-overflow-style: none;
  -webkit-scrollbar-width: none;
  scrollbar-width: none;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-scroll-snap-type: x proximity;
  scroll-snap-type: x proximity;
  height: 100%;
  width: 100%;
  position: fixed;
  align-items: center;
  padding: 0 calc(50vw - calc(100vw - 48px)/2 - 4px);
  cursor: pointer;
}
.mindai-section-modal--panel{
  position: relative;
  display: inline-block;
  background: var(--mindai-section-color--white);
  height: calc(98vh - 48px - 24px);
  min-height: calc(98vh - 48px - 24px);
  max-height: calc(98vh - 48px - 24px);
  margin: -16px 4px 0;
  overflow-y: auto;
  border-radius: var(--mindai-section-size--2x);
  box-shadow: 0 var(--mindai-section-size--half) var(--mindai-section-size--4x) 0 transparent;
  transition: filter .5s ease;
  margin-left: 4px;
  margin-right: 4px;
  scroll-snap-align: center!important;
  width: calc(80vw - 48px);
  min-width: calc(80vw - 48px);
  max-width: calc(80vw - 48px);
  visibility: inherit;
  scrollbar-width: none; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.mindai-section-modal--panel-list{
  inline-size: 100%;
}

@media (max-width:599px){
  .mindai-section-modal--panel {
    min-height: 80vh!important;
    height: 80vh!important; 
    width: calc(100vw - 48px);
    min-width: calc(100vw - 48px);
    max-width: calc(100vw - 48px);
}
}
.mindai-section-modal--panel__active {
  box-shadow: 0 var(--mindai-section-size--half) var(--mindai-section-size--4x) 0 rgba(0,0,0,.25);
  cursor: auto;
}
@media (max-width: 599px){
  .mindai-section-content-groupComponent--container {
      padding: var(--mindai-section-size--5x) var(--mindai-section-size--3x) var(--mindai-section-size--10x) var(--mindai-section-size--3x);
      height: 80vh;
  }
}

.modal-wallet-content{
  background-color: #fefefe;
  padding: 48px 48px 80px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 110px -60px  rgb(89, 89, 224);
 
}

.modal-wallet-select-provider{
  border: none;
  width: 100%;
  height: auto;
  position: relative;
}

.mindai-layout-modal-wallet-select-provider{
  border: none;
  width: 100%;
  height: 100%;
  position: relative;
}
.mindai-layout-modal-wallet-select-provider.mindai-layout-type-provider{
  width: 100%;  
  font-family: var(--mindai-base-font-family-combination);
  font-size: 20px;
  text-align: center;
  height: 60px; 
  border-radius: 4px;
  background-color: var(--deactive-type-tab-button);
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
}
.mindai-layout-type-provider.white {
  background-color: white;
  border-color: #1c58d1;
  border-width: 2px;
  border-style: solid;
  font-family: var(--mindai-base-font-family-combination); 
}
.mindai-layout-type-provider{
  width: auto;
  border-radius: 4px;
  cursor: pointer;
  height: 55px;
  margin-top: 3px;
  margin-left: 3px;
  margin-right: 3px;
  white-space: nowrap;
  text-align: center;
  background-color: transparent;
  border: none;
  font-size: 15px;
}
.modal-wallet-select-provider.type-provider{
  width: 100%;  
  font-family: var(--mindai-base-font-family-combination);
  font-size: 20px;
  text-align: center;
  block-size: var(--mindai-section-size--4x); 
  border-radius: 4px;
  background-color: var(--deactive-type-tab-button);
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
}

.type-provider{
  width: auto;
  border-radius: 4px;
  cursor: pointer;
  height: 55px;
  margin-top: 3px;
  margin-left: 3px;
  margin-right: 3px;
  white-space: nowrap;
  text-align: center;
  background-color: transparent;
  border: none;
  font-size: 15px;
}

.type-provider.white {
  background-color: white;
  border-color: #1c58d1;
  border-width: 2px;
  border-style: solid;
  font-family: var(--mindai-base-font-family-combination);
 
  
}


.summary{
  color: #393c41;
  height: auto;
  position: relative;
}

.summary-list{
  line-height: 21px;

}

.summary-list {
   --separator-color: #d0d1d2;
  --timeline-color: #393c41;
  --item--margin-block: 8px;
  --list-item--padding-inline: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.summary-list>li {
  -webkit-padding-end:0;
  padding-inline-end: 0;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  position: relative;
}
.summary-list-item{
  flex-flow: row;
  padding: 0;
}
.tflex{
  display: flex;
  flex-flow: column wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.flex-item{
  font-size: 14px;
  font-family: var(--mindai-base-font-family-combination); 
  font-weight: 300;
  flex: 1;
  padding: 4px 0px;
}

.price-container{
  position: relative;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  
}

.vertical_padding{
  -webkit-padding-after:16px!important;
  padding-block-end: 16px!important;
  -webkit-padding-before: 16px!important;
  padding-block-start: 16px!important;
}
/* SCREEN SIZE HERE */
a{
  -webkit-appearance: none;
  appearance: none;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
} 

.mindai-layout-modal-image{
  height: auto;
  max-height: 300px;
  display: block; 
}
.mindai-layout-modal-image__picture{
  height: 100%; 
}

.mindai-layout-objectfit-position--center{
  object-position: 50% 50%;
}

.mindai-layout-modal-image--asset{
  height: auto;
  object-fit: contain;
  max-height: 300px;

}

.mindai-layout-button-group{
  --mindai-layout-button-min-inline-size: 204px;
  display: block;
  gap: 16px 24px;
  gap: 16px var(--mindai-section-padding-size--3x);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; 
}
.mindai-layout-button-group--one-button{ 
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  margin: auto;
}
.mindai-section-button{
  --mindai-section-button--border-color: transparent;
 --mindai-section-button--border-radius: 4px;
 align-items: center;
 background-color: var(--mindai-section-button--background-color);
 block-size: 40px;
 border: 2px solid var(--mindai-section-button--border-color);
 border-radius: var(--mindai-section-button--border-radius);
 box-shadow: inset 0 0 0 2px transparent;
 color: var(--mindai-section-foreground-high-contrast);
 display: inline-flex;
 font-family: var(--mindai-base-font-family-combination);
 font-size: 14px;
 font-weight: 500;
 justify-content: center;
 line-height: 1.2;
 padding: 4px 24px;
 position: relative;
 text-align: center;
 text-transform: inherit; 
 transition: border-color 1s ease,background-color .55s ease,color .33s ease,box-shadow .25s ease;
}
.mindai-section-button:hover{
 background-color: var(--mindai-section-foreground-high-contrast);
 color: var(--mindai-section-button--background-color);
 border-color: rgb(0, 0, 0);
}
[dir=ltr].mindai-layout-button{
  margin-left: 0; 
}
[dir=ltr].mindai-layout-button{
  margin-right: 0; 
}
.mindai-layout-button{
  -webkit-margin-end: 0; 
  -webkit-margin-start: 0; 
  -webkit-backdrop-filter: none;
  backdrop-filter: none;  
  margin-bottom: 0; 
  margin-inline-end: 0; 
  margin-inline-start: 0; 
  margin-top: 0; 
  text-transform: inherit; 
}
.mindai-layout-button-group .mindai-layout-button{
  min-width: auto!important;
 }

 .mindai-section-button--primary{
  --mindai-section-button--background-color: var(--mindai-section-color-black);
  --mindai-section-button--border-color: var(--mindai-section-foreground-high-contrast);
  color: var(--mindai-section-foreground-high-contrast);
}
@media (max-width: 599px) {  
  .modal-wallet-content{
    padding: 48px 24px 36px; 
  }
  .presale-header{
    font-size: 28px;
    line-height: 36px;
  } 

  .mindai-section-modal{
    min-block-size: 25vh!important;
  }
  .mindai-layout-modal-type-wallet-connection{ 
    bottom: 0;
    display: flex;
    flex-direction: column;
    inline-size: auto;
  }
}
:root{

  --active-type-tab-button: white;
  --deactive-type-tab-button:  hsla(0,0%,58.8%,0.1); 
}