

/* No Grid */
/* --------------------------------------- */
.main-overview-presale{
    position: relative;
     
}


.-no_horizontal_padding {
    -webkit-padding-end: 0!important;
    padding-inline-end: 0!important;
    -webkit-padding-start: 0!important;
    padding-inline-start: 0!important;
  }


.main-overview.image.container{
    height: 100%;
    max-height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;

}
.main-overview.image.container{
overflow: hidden;

}

.main-overview-presale.image{
height: auto;
position: relative;
background-color: white;
}



.main-overview.description{ 
    width: 100%;  
    height: auto;
    font-family: var(--mindai-base-font-family-combination); 
    text-align: center; 
    position: relative;
}

.main-overview.description.presale-estimated-end{
    width: 100%;  
    font-family: var(--mindai-base-font-family-combination);
    font-size: 20px;
    text-align: center; 
    height: 50px;  
    position: relative;
}

.summary__no-grid{ 
    color: #393c41;
    height: auto;
    position: relative; 
    padding-inline-start: var(--mindai-section-size--8x);
    padding-inline-end: var(--mindai-section-size--8x);
}

.summary__no-grid-list{
    line-height: 21px; 
    padding: 8px 
}
  
.summary__no-grid-list-item{
    flex-flow: row;
    padding: 0;
}
.tflex__no-grid{
    display: flex;
    flex-flow: column wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.flex-item__no-grid{
    font-size: 14px;
    font-family: var(--mindai-base-font-family-combination); 
    font-weight: 300;
    flex: 1;
}
  
.allocation-resume__no-grid {
    width: 100%;  
    font-family: var(--mindai-base-font-family-combination); 
    text-align: center; 
    block-size: 40px;
    border-radius: 4px;
    background-color: transparent;
    position: relative; 
    max-width: 100%;
    max-height: 100%;  
    display: grid;
    grid-template-columns: 18% 18% 18% 18% 18%; 
    border-width: 3px; 
    padding: 0px 0px 10px;
    justify-content: center;
    align-items: center;
    gap: 1px;
     
}
.allocation-resume__no-grid-item{
    overflow: hidden; 
    cursor: pointer;
    box-shadow: 0px 0px 10px -5px;
    border-radius: 4px; 
    position: relative;
    margin: auto;  
    justify-content: center; 
    background-color: #fff;
    padding: 0; 
    display: flex;
    height: 100%;
    text-align: center;
    width: 90% ;
  }

.main-overview.description.type-buyer_nogrid{
    width: 80%;  
    font-family: var(--mindai-base-font-family-combination);
    font-size: 20px;  
    text-align: center;
    margin-top: 3%;
    height: 60px;
    /* border-radius: 28px; */
    border-radius: 4px;
    background-color: #4cdadaef;
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    
}


.type-tab__no_grid{
    width: auto;
    /* border-radius: 27px; */
    border-radius: 4px;
    cursor: pointer;
    height: 55px;
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
    white-space: nowrap;
    text-align: center;
    background-color: var(--deactive-type-tab-button);
    border: none;
}
.type-tab__no_grid.white {
    background-color: rgb(255, 255, 255);
    border-color: #1c58d1;
    border-width: 3px;
    border-style: solid;
    
}

.main-overview.description.pro-user__no_grid{
    width: 100%;  
    font-family: var(--mindai-base-font-family-combination); 
    font-size: 20px;
    text-align: center;
    height: auto;
    position: relative;

}
.main-overview-presale__description__wallet-connect__nogrid{
    width: 95%;  
    font-family: var(--mindai-base-font-family-combination);
    font-size: 20px;
    text-align: center;
    margin-top: 3%;
    height: 45px; 
    border-radius: 4px; 
    position: relative;
    display: flex; 
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; 
    overflow: auto;
  }

.function-btn__no_grid{
    width: 90%;
    border-radius: 4px;
    cursor: pointer;
    height: 100%;
    font-family: var(--mindai-base-font-family-combination);
    white-space: nowrap;
    text-align: center;
    background-color: white;
    border-width: 2px;
    border-style: solid;
    color: black;
    border-color: #1c58d1;
    transition:  border-color 1s ease,background-color .55s ease,color .33s ease;
    font-size: 15px;
    font-weight:800;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}
.function-btn__no_grid:hover{
    border-radius: 4px;
    background-color: blue;
    color: white;
    font-size: 15px;
    font-weight: 800;
}

.main-presale__no-grid-progress-bar-container{
    block-size: 90px;
    position: relative;
    display: flex;
}

.main-presale__no-grid-progress-bar{
    width: 90%;  
    font-family: var(--mindai-base-font-family-combination); 
    text-align: center; 
    block-size: auto;
    border-radius: 4px;
    background-color: transparent;
    position: relative;  
    display: flex; 
    border-width: 3px; 
    padding: 0px 0px ;
    justify-content: center;
    align-items: center;
    gap: 4px; 
    left: 0;
    right: 0;
    bottom: 0;
    margin:auto;
  }

.main-presale__no-grid-progress-bar-content{
    overflow: hidden; 
    cursor: pointer;
    box-shadow: 0px 0px 10px -5px;
    border-radius: 4px; 
    position: relative;
    margin: auto;   
    padding: 0  ; 
    display: flex;  
    height: 30px;
    width: 98%;
    background-color: #7f9696ef;
    justify-content: initial;
    justify-items: initial;
    align-items: initial;
  }

.main-placeholder {
    overflow: hidden;
    position: relative;
}
 
@media (max-width: 600px){
    .summary__no-grid{
        padding-inline-start: var(--mindai-section-size--2x);
        padding-inline-end: var(--mindai-section-size--2x);
    }
}
@media (min-width: 600px) and (max-width: 899px){
    .option-widget {
        margin: 32px 0;
    }
    .main-overview-presale{
        position: relative; 
    } 
    .-no_horizontal_padding {
        -webkit-padding-end: 0!important;
        padding-inline-end: 0!important;
        -webkit-padding-start: 0!important;
        padding-inline-start: 0!important;
    } 
    .main-overview.image.container{
        height: 100%;
        max-height: 100%;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    
    }
    .main-overview.image.container{
    overflow: hidden;
    
    }
    
    .main-overview-presale.image{
    height: auto;
    position: relative;
    background-color: white;
    }
    
    
    
    .main-overview.description{ 
        width: 100%;  
        height: auto;
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        position: relative;
    }
    
    .main-overview.description.presale-estimated-end{
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 20px;
        text-align: center; 
        height: 50px;  
        position: relative;
    }
    
    .summary__no-grid{ 
        color: #393c41;
        height: auto;
        position: relative; 
        
    }
    
    .summary__no-grid-list{
        line-height: 21px; 
        padding-inline-start: 24px;
        padding-inline-end: 24px;
    }
      
    .summary__no-grid-list-item{
        /* flex-flow: row; */
        padding: 0;
    }
    .tflex__no-grid{
        display: block;
        flex-flow: column wrap;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    
    .flex-item__no-grid{
        font-size: 14px;
        font-family: var(--mindai-base-font-family-combination); 
        font-weight: 300;
        flex: 1;
    }
      
    .allocation-resume__no-grid {
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        block-size: 40px;
        border-radius: 4px;
        background-color: transparent;
        position: relative; 
        max-width: 100%;
        max-height: 100%;  
        display: grid;
        grid-template-columns: 18% 18% 18% 18% 18%; 
        border-width: 3px; 
        padding: 0px 0px 10px;
        justify-content: center;
        align-items: center;
        gap: 1px;
         
    }
    .allocation-resume__no-grid-item{
        overflow: hidden; 
        cursor: pointer;
        box-shadow: 0px 0px 10px -5px;
        border-radius: 4px; 
        position: relative;
        margin: auto;  
        justify-content: center; 
        background-color: #fff;
        padding: 0; 
        display: flex;
        height: 100%;
        text-align: center;
        width: 90% ;
      }
    
    .main-overview.description.type-buyer_nogrid{
        width: 80%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 20px;  
        text-align: center;
        margin-top: 3%;
        height: 60px;
        /* border-radius: 28px; */
        border-radius: 4px;
        background-color: #4cdadaef;
        position: relative;
        display: grid;
        grid-template-columns: 50% 50%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
        
    }
    
    
    .type-tab__no_grid{
        width: auto;
        /* border-radius: 27px; */
        border-radius: 4px;
        cursor: pointer;
        height: 55px;
        margin-top: 3px;
        margin-left: 3px;
        margin-right: 3px;
        white-space: nowrap;
        text-align: center;
        background-color: var(--deactive-type-tab-button);
        border: none;
    }
    .type-tab__no_grid.white {
        background-color: rgb(255, 255, 255);
        border-color: #1c58d1;
        border-width: 3px;
        border-style: solid;
        
    }
    
    .main-overview.description.pro-user__no_grid{
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination); 
        font-size: 20px;
        text-align: center;
        height: auto;
        position: relative;
    
    }
    .main-overview-presale__description__wallet-connect__nogrid{
        width: 95%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 20px;
        text-align: center;
        margin-top: 3%;
        height: 45px; 
        border-radius: 4px; 
        position: relative;
        display: flex; 
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; 
        overflow: auto;
      }
    
    .function-btn__no_grid{
        width: 90%;
        border-radius: 4px;
        cursor: pointer;
        height: 100%;
        font-family: var(--mindai-base-font-family-combination);
        white-space: nowrap;
        text-align: center;
        background-color: white;
        border-width: 2px;
        border-style: solid;
        color: black;
        border-color: #1c58d1;
        transition:  border-color 1s ease,background-color .55s ease,color .33s ease;
        font-size: 15px;
        font-weight:800;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
    }
    .function-btn__no_grid:hover{
        border-radius: 4px;
        background-color: blue;
        color: white;
        font-size: 15px;
        font-weight: 800;
    }
    
    .main-presale__no-grid-progress-bar-container{
        block-size: 90px;
        position: relative;
        display: flex;
    }
    
    .main-presale__no-grid-progress-bar{
        width: 90%;  
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        block-size: auto;
        border-radius: 4px;
        background-color: transparent;
        position: relative;  
        display: flex; 
        border-width: 3px; 
        padding: 0px 0px ;
        justify-content: center;
        align-items: center;
        gap: 4px; 
        left: 0;
        right: 0;
        bottom: 0;
        margin:auto;
      }
    
    .main-presale__no-grid-progress-bar-content{
        overflow: hidden; 
        cursor: pointer;
        box-shadow: 0px 0px 10px -5px;
        border-radius: 4px; 
        position: relative;
        margin: auto;   
        padding: 0  ; 
        display: flex;  
        height: 30px;
        width: 98%;
        background-color: #7f9696ef;
        justify-content: initial;
        justify-items: initial;
        align-items: initial;
      }
    
    .main-placeholder {
        overflow: hidden;
        position: relative;
    }
}


@media(min-width: 900px) and (max-width: 1024px){
    .summary__no-grid{
        padding-inline-start: var(--mindai-section-size--12x);
        padding-inline-end: var(--mindai-section-size--12x);
    } 
    .function-btn{
        width: 55%;
    }
}


/* @media (min-width: 900px ){
    .summary__no-grid{
        padding-inline-start: var(--mindai-section-size--8x);
        padding-inline-end: var(--mindai-section-size--8x);
    }
} */

/*  
@media (min-width: 600px) {
    .option-widget {
        margin: 32px 0;
    }
    .main-overview-presale{
        position: relative;
         
    }
    
    
    .-no_horizontal_padding {
        -webkit-padding-end: 0!important;
        padding-inline-end: 0!important;
        -webkit-padding-start: 0!important;
        padding-inline-start: 0!important;
      }
    
    
    .main-overview.image.container{
        height: 100%;
        max-height: 100%;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    
    }
    .main-overview.image.container{
     overflow: hidden;
    
    }
    
    .main-overview-presale.image{
        height: auto;
        position: relative;
        background-color: white;
    }
    
    
    
    .main-overview.description{ 
        width: 100%;  
        height: auto;
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        position: relative;
    }
    
    .main-overview.description.presale-estimated-end{
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 16px;
        text-align: center; 
        height: 40px;  
        position: relative;
    }
    
    .summary__no-grid{ 
        color: #393c41;
        height: auto;
        position: relative; 
    }
    
    .summary__no-grid-list{
        line-height: 19px; 
        padding: 8px 
    }
      
    .summary__no-grid-list-item{
        flex-flow: row;
        padding: 0;
    }
    .tflex__no-grid{
        display: flex;
        flex-flow: column wrap;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    
    .flex-item__no-grid{
        font-size: 16px;
        font-family: var(--mindai-base-font-family-combination); 
        font-weight: 300;
        flex: 1;
    }
      
    .allocation-resume__no-grid {
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        block-size: 50px;
        border-radius: 4px;
        background-color: transparent;
        position: relative; 
        max-width: 100%;
        max-height: 100%;  
        display: grid;
        grid-template-columns: 18% 18% 18% 18% 18%; 
        border-width: 3px; 
        padding: 0px 0px 10px;
        justify-content: center;
        align-items: center;
        gap: 1px;
         
    }
    .allocation-resume__no-grid-item{
        overflow: hidden; 
        cursor: pointer;
        box-shadow: 0px 0px 10px -5px;
        border-radius: 4px; 
        position: relative;
        margin: auto;  
        justify-content: center; 
        background-color: #fff;
        padding: 0; 
        display: flex;
        height: 100%;
        text-align: center;
        width: 90% ;
      }
    
    .main-overview.description.type-buyer_nogrid{
        width: 80%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 22px;  
        text-align: center; 
        height: 60px; 
        border-radius: 4px;
        background-color: #4cdadaef;
        position: relative;
        display: grid;
        grid-template-columns: 50% 50%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
        
    }
    
    
    .type-tab__no_grid{
        --background-color: transparent;
        --box-shadow--focus: inset 0 0 0 transparent;
        --box-shadow--highlight: 0 0 0 transparent;
        align-items: center;
        block-size: calc(40 - 8px);
        border-radius: 4px;
        display: inline-flex;
        font-family: "Gotham SSm", -apple-system,  BlinkMacSystemFont, 'Segoe UI', Roboto,'Helvetica Neue', sans-serif !important;
        font-size: 12px;
        font-weight: 500;
        justify-content: center;
        line-height: 1.2;
        padding: 4px 24px;
        position: relative;
        color: #5c5e62;
        background-color: var(--tds-tab--background-color);
        scroll-snap-align: center;
        box-shadow: inset 0 0 0 transparent, 0 4px  8px 0 rgba(0, 0, 0, 0.08);
        cursor: pointer;
        transition: background-color .3s ease,box-shadow .3s ease,color .3s ease;
    }

    .type-tab__no_grid.white {
        background-color: white;
        border-color: none;
        border-width: 0;
        border-style: solid;
        
    }
    
    .main-overview.description.pro-user__no_grid{
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination); 
        font-size: 22px;
        text-align: center;
        height: auto;
        position: relative;
    
    }
    .main-overview-presale__description__wallet-connect__nogrid{
        width: 95%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 20px;
        text-align: center;
        margin-top: 3%;
        height: 45px; 
        border-radius: 4px; 
        position: relative;
        display: flex; 
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; 
        overflow: auto;
      }
    
    .function-btn__no_grid{
        width: 90%;
        border-radius: 4px;
        cursor: pointer;
        height: 100%;
        font-family: var(--mindai-base-font-family-combination);
        white-space: nowrap;
        text-align: center;
        background-color: white;
        border-width: 2px;
        border-style: solid;
        color: black;
        border-color: #1c58d1;
        transition:  border-color 1s ease,background-color .55s ease,color .33s ease;
        font-size: 18px;
        font-weight:800;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
    }
    .function-btn__no_grid:hover{
        border-radius: 4px;
        background-color: blue;
        color: white;
        font-size: 18px;
        font-weight: 800;
    }
    
    .main-presale__no-grid-progress-bar-container{
        block-size: 90px;
        position: relative;
        display: flex;
    }
    
    .main-presale__no-grid-progress-bar{
        width: 90%;  
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        block-size: auto;
        border-radius: 4px;
        background-color: transparent;
        position: relative;  
        display: flex; 
        border-width: 3px; 
        padding: 0px 0px ;
        justify-content: center;
        align-items: center;
        gap: 4px; 
        left: 0;
        right: 0;
        bottom: 0;
        margin:auto;
      }
    
    .main-presale__no-grid-progress-bar-content{
        overflow: hidden; 
        cursor: pointer;
        box-shadow: 0px 0px 10px -5px;
        border-radius: 4px; 
        position: relative;
        margin: auto;   
        padding: 0  ; 
        display: flex;  
        height: 30px;
        width: 98%;
        background-color: #7f9696ef;
        justify-content: initial;
        justify-items: initial;
        align-items: initial;
      }
    
    .main-placeholder {
        overflow: hidden;
        position: relative;
    }
} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
    .section-main-content-buy{ 
        height: 100%; 
     }
      
     
     section {
       transition: opacity 1s cubic-bezier(0.5,0,0,0.75),transform 1s cubic-bezier(0.5,0,0,0.75);
     }
      
    .option-widget {
        margin: 32px 0;
    }
    .main-overview-presale{
        position: relative;
         
    }
    
    
    .-no_horizontal_padding {
        -webkit-padding-end: 0!important;
        padding-inline-end: 0!important;
        -webkit-padding-start: 0!important;
        padding-inline-start: 0!important;
      }
    
    
    .main-overview.image.container{
        height: 100%;
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    
    }
    .main-overview.image.container{
    overflow: hidden;
    
    }
    
    .main-overview-presale.image{
    height: auto;
    position: relative;
    background-color: white;
    }
    
    
    
    .main-overview.description{ 
        width: 100%;  
        height: auto;
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        position: relative;
    }
    
    .main-overview.description.presale-estimated-end{
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 24px;
        text-align: center; 
        height: 40px;  
        position: relative;
    }
    
    .summary__no-grid{ 
        color: #393c41;
        height: auto;
        position: relative; 
    }
    
    .summary__no-grid-list{
        line-height: 21px; 
        padding: 8px 
    }
      
    .summary__no-grid-list-item{
        flex-flow: row;
        padding: 0;
    }
    .tflex__no-grid{
        display: flex;
        flex-flow: column wrap;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    
    .flex-item__no-grid{
        font-size: 14px;
        font-family: var(--mindai-base-font-family-combination); 
        font-weight: 300;
        flex: 1;
    }
      
    .allocation-resume__no-grid {
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        block-size: 40px;
        border-radius: 4px;
        background-color: transparent;
        position: relative; 
        max-width: 100%;
        max-height: 100%;  
        display: grid;
        grid-template-columns: 18% 18% 18% 18% 18%; 
        border-width: 3px; 
        padding: 0px 0px 10px;
        justify-content: center;
        align-items: center;
        gap: 1px;
         
    }
    .allocation-resume__no-grid-item{
        overflow: hidden; 
        cursor: pointer;
        box-shadow: 0px 0px 10px -5px;
        border-radius: 4px; 
        position: relative;
        margin: auto;  
        justify-content: center; 
        background-color: #fff;
        padding: 0; 
        display: flex;
        height: 100%;
        text-align: center;
        width: 90% ;
      }
    
    .main-overview.description.type-buyer_nogrid{
        width: 80%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 20px;  
        text-align: center;
        margin-top: 3%;
        height: 60px; 
        border-radius: 4px;
        background-color: #4cdadaef;
        position: relative;
        display: grid;
        grid-template-columns: 50% 50%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
        
    }
    
    
    .type-tab__no_grid{
        width: auto; 
        border-radius: 4px;
        cursor: pointer;
        height: 55px;
        margin-top: 3px;
        margin-left: 3px;
        margin-right: 3px;
        white-space: nowrap;
        text-align: center;
        background-color: var(--deactive-type-tab-button);
        border: none;
    }
    .type-tab__no_grid.white {
        background-color: rgb(255, 255, 255);
        border-color: #1c58d1;
        border-width: 3px;
        border-style: solid;
        
    }
    
    .main-overview.description.pro-user__no_grid{
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination); 
        font-size: 20px;
        text-align: center;
        height: auto;
        position: relative;
    
    }
    .main-overview-presale__description__wallet-connect__nogrid{
        width: 95%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 20px;
        text-align: center;
        margin-top: 3%;
        height: 45px; 
        border-radius: 4px; 
        position: relative;
        display: flex; 
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; 
        overflow: auto;
      }
    
    .function-btn__no_grid{
        width: 90%;
        border-radius: 4px;
        cursor: pointer;
        height: 100%;
        font-family: var(--mindai-base-font-family-combination);
        white-space: nowrap;
        text-align: center;
        background-color: white;
        border-width: 2px;
        border-style: solid;
        color: black;
        border-color: #1c58d1;
        transition:  border-color 1s ease,background-color .55s ease,color .33s ease;
        font-size: 15px;
        font-weight:800;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
    }
    .function-btn__no_grid:hover{
        border-radius: 4px;
        background-color: blue;
        color: white;
        font-size: 15px;
        font-weight: 800;
    }
    
    .main-presale__no-grid-progress-bar-container{
        block-size: 90px;
        position: relative;
        display: flex;
    }
    
    .main-presale__no-grid-progress-bar{
        width: 90%;  
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        block-size: auto;
        border-radius: 4px;
        background-color: transparent;
        position: relative;  
        display: flex; 
        border-width: 3px; 
        padding: 0px 0px ;
        justify-content: center;
        align-items: center;
        gap: 4px; 
        left: 0;
        right: 0;
        bottom: 0;
        margin:auto;
      }
    
    .main-presale__no-grid-progress-bar-content{
        overflow: hidden; 
        cursor: pointer;
        box-shadow: 0px 0px 10px -5px;
        border-radius: 4px; 
        position: relative;
        margin: auto;   
        padding: 0  ; 
        display: flex;  
        height: 30px;
        width: 98%;
        background-color: #7f9696ef;
        justify-content: initial;
        justify-items: initial;
        align-items: initial;
      }
    
    .main-placeholder {
        overflow: hidden;
        position: relative;
    }
}
  */
/* @media only screen and (min-width: 768px) {
    .option-widget {
        margin: 32px 0;
    }
    .main-overview-presale{
        position: relative;
         
    }
    
    
    .-no_horizontal_padding {
        -webkit-padding-end: 0!important;
        padding-inline-end: 0!important;
        -webkit-padding-start: 0!important;
        padding-inline-start: 0!important;
      }
    
    
    .main-overview.image.container{
        height: 100%;
        max-height: 100%;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    
    }
    .main-overview.image.container{
    overflow: hidden;
    
    }
    
    .main-overview-presale.image{
    height: auto;
    position: relative;
    background-color: white;
    }
    
    
    
    .main-overview.description{ 
        width: 100%;  
        height: auto;
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        position: relative;
    }
    
    .main-overview.description.presale-estimated-end{
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 20px;
        text-align: center; 
        height: 50px;  
        position: relative;
    }
    
    .summary__no-grid{ 
        color: #393c41;
        height: auto;
        position: relative; 
    }
    
    .summary__no-grid-list{
        line-height: 21px; 
        padding: 8px 
    }
      
    .summary__no-grid-list-item{
        flex-flow: row;
        padding: 0;
    }
    .tflex__no-grid{
        display: flex;
        flex-flow: column wrap;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    
    .flex-item__no-grid{
        font-size: 14px;
        font-family: var(--mindai-base-font-family-combination); 
        font-weight: 300;
        flex: 1;
    }
      
    .allocation-resume__no-grid {
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        block-size: 40px;
        border-radius: 4px;
        background-color: transparent;
        position: relative; 
        max-width: 100%;
        max-height: 100%;  
        display: grid;
        grid-template-columns: 18% 18% 18% 18% 18%; 
        border-width: 3px; 
        padding: 0px 0px 10px;
        justify-content: center;
        align-items: center;
        gap: 1px;
         
    }
    .allocation-resume__no-grid-item{
        overflow: hidden; 
        cursor: pointer;
        box-shadow: 0px 0px 10px -5px;
        border-radius: 4px; 
        position: relative;
        margin: auto;  
        justify-content: center; 
        background-color: #fff;
        padding: 0; 
        display: flex;
        height: 100%;
        text-align: center;
        width: 90% ;
      }
    
    .main-overview.description.type-buyer_nogrid{
        width: 80%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 20px;  
        text-align: center;
        margin-top: 3%;
        height: 60px;
        /* border-radius: 28px; */
        /* border-radius: 4px;
        background-color: #4cdadaef;
        position: relative;
        display: grid;
        grid-template-columns: 50% 50%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        overflow: auto; */
        
    /* } */
    
/*     
    .type-tab__no_grid{
        width: auto; */
        /* border-radius: 27px; */
        /* border-radius: 4px;
        cursor: pointer;
        height: 55px;
        margin-top: 3px;
        margin-left: 3px;
        margin-right: 3px;
        white-space: nowrap;
        text-align: center;
        background-color: var(--deactive-type-tab-button);
        border: none; */
    /* } */
    /* .type-tab__no_grid.white {
        background-color: rgb(255, 255, 255);
        border-color: #1c58d1;
        border-width: 3px;
        border-style: solid;
        
    } */
    
    /* .main-overview.description.pro-user__no_grid{
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination); 
        font-size: 20px;
        text-align: center;
        height: auto;
        position: relative;
    
    }
    .main-overview-presale__description__wallet-connect__nogrid{
        width: 95%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 20px;
        text-align: center;
        margin-top: 3%;
        height: 45px; 
        border-radius: 4px; 
        position: relative;
        display: flex; 
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; 
        overflow: auto;
      }
    
    .function-btn__no_grid{
        width: 90%;
        border-radius: 4px;
        cursor: pointer;
        height: 100%;
        font-family: var(--mindai-base-font-family-combination);
        white-space: nowrap;
        text-align: center;
        background-color: white;
        border-width: 2px;
        border-style: solid;
        color: black;
        border-color: #1c58d1;
        transition:  border-color 1s ease,background-color .55s ease,color .33s ease;
        font-size: 15px;
        font-weight:800;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
    }
    .function-btn__no_grid:hover{
        border-radius: 4px;
        background-color: blue;
        color: white;
        font-size: 15px;
        font-weight: 800;
    }
    
    .main-presale__no-grid-progress-bar-container{
        block-size: 90px;
        position: relative;
        display: flex;
    }
    
    .main-presale__no-grid-progress-bar{
        width: 90%;  
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        block-size: auto;
        border-radius: 4px;
        background-color: transparent;
        position: relative;  
        display: flex; 
        border-width: 3px; 
        padding: 0px 0px ;
        justify-content: center;
        align-items: center;
        gap: 4px; 
        left: 0;
        right: 0;
        bottom: 0;
        margin:auto;
      }
    
    .main-presale__no-grid-progress-bar-content{
        overflow: hidden; 
        cursor: pointer;
        box-shadow: 0px 0px 10px -5px;
        border-radius: 4px; 
        position: relative;
        margin: auto;   
        padding: 0  ; 
        display: flex;  
        height: 30px;
        width: 98%;
        background-color: #7f9696ef;
        justify-content: initial;
        justify-items: initial;
        align-items: initial;
      }
    
    .main-placeholder {
        overflow: hidden;
        position: relative;
    }
} */  
 
/* @media only screen and (min-width: 992px) {
    .option-widget {
        margin: 32px 0;
    }
    .main-overview-presale{
        position: relative;
         
    }
    
    
    .-no_horizontal_padding {
        -webkit-padding-end: 0!important;
        padding-inline-end: 0!important;
        -webkit-padding-start: 0!important;
        padding-inline-start: 0!important;
      }
    
    
    .main-overview.image.container{
        height: 100%;
        max-height: 100%;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    
    }
    .main-overview.image.container{
    overflow: hidden;
    
    }
    
    .main-overview-presale.image{
    height: auto;
    position: relative;
    background-color: white;
    }
    
    
    
    .main-overview.description{ 
        width: 100%;  
        height: auto;
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        position: relative;
    }
    
    .main-overview.description.presale-estimated-end{
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 20px;
        text-align: center; 
        height: 50px;  
        position: relative;
    }
    
    .summary__no-grid{ 
        color: #393c41;
        height: auto;
        position: relative; 
    }
    
    .summary__no-grid-list{
        line-height: 21px; 
        padding: 8px 
    }
      
    .summary__no-grid-list-item{
        flex-flow: row;
        padding: 0;
    }
    .tflex__no-grid{
        display: flex;
        flex-flow: column wrap;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    
    .flex-item__no-grid{
        font-size: 14px;
        font-family: var(--mindai-base-font-family-combination); 
        font-weight: 300;
        flex: 1;
    }
      
    .allocation-resume__no-grid {
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        block-size: 40px;
        border-radius: 4px;
        background-color: transparent;
        position: relative; 
        max-width: 100%;
        max-height: 100%;  
        display: grid;
        grid-template-columns: 18% 18% 18% 18% 18%; 
        border-width: 3px; 
        padding: 0px 0px 10px;
        justify-content: center;
        align-items: center;
        gap: 1px;
         
    }
    .allocation-resume__no-grid-item{
        overflow: hidden; 
        cursor: pointer;
        box-shadow: 0px 0px 10px -5px;
        border-radius: 4px; 
        position: relative;
        margin: auto;  
        justify-content: center; 
        background-color: #fff;
        padding: 0; 
        display: flex;
        height: 100%;
        text-align: center;
        width: 90% ;
      }
    
    .main-overview.description.type-buyer_nogrid{
        width: 80%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 20px;  
        text-align: center;
        margin-top: 3%;
        height: 60px;
        /* border-radius: 28px; */
        /* border-radius: 4px;
        background-color: #4cdadaef;
        position: relative;
        display: grid;
        grid-template-columns: 50% 50%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        overflow: auto; */
        
    /* } */
    
    
    /* .type-tab__no_grid{
        width: auto; */
        /* border-radius: 27px; */
        /* border-radius: 4px;
        cursor: pointer;
        height: 55px;
        margin-top: 3px;
        margin-left: 3px;
        margin-right: 3px;
        white-space: nowrap;
        text-align: center;
        background-color: var(--deactive-type-tab-button);
        border: none;
    }
    .type-tab__no_grid.white {
        background-color: rgb(255, 255, 255);
        border-color: #1c58d1;
        border-width: 3px;
        border-style: solid; */
        
    /* }
    
    .main-overview.description.pro-user__no_grid{
        width: 100%;  
        font-family: var(--mindai-base-font-family-combination); 
        font-size: 20px;
        text-align: center;
        height: auto;
        position: relative;
    
    }
    .main-overview-presale__description__wallet-connect__nogrid{
        width: 95%;  
        font-family: var(--mindai-base-font-family-combination);
        font-size: 20px;
        text-align: center;
        margin-top: 3%;
        height: 45px; 
        border-radius: 4px; 
        position: relative;
        display: flex; 
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; 
        overflow: auto;
      }
    
    .function-btn__no_grid{
        width: 90%;
        border-radius: 4px;
        cursor: pointer;
        height: 100%;
        font-family: var(--mindai-base-font-family-combination);
        white-space: nowrap;
        text-align: center;
        background-color: white;
        border-width: 2px;
        border-style: solid;
        color: black;
        border-color: #1c58d1;
        transition:  border-color 1s ease,background-color .55s ease,color .33s ease;
        font-size: 15px;
        font-weight:800;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
    }
    .function-btn__no_grid:hover{
        border-radius: 4px;
        background-color: blue;
        color: white;
        font-size: 15px;
        font-weight: 800;
    }
    
    .main-presale__no-grid-progress-bar-container{
        block-size: 90px;
        position: relative;
        display: flex;
    }
    
    .main-presale__no-grid-progress-bar{
        width: 90%;  
        font-family: var(--mindai-base-font-family-combination); 
        text-align: center; 
        block-size: auto;
        border-radius: 4px;
        background-color: transparent;
        position: relative;  
        display: flex; 
        border-width: 3px; 
        padding: 0px 0px ;
        justify-content: center;
        align-items: center;
        gap: 4px; 
        left: 0;
        right: 0;
        bottom: 0;
        margin:auto;
      }
    
    .main-presale__no-grid-progress-bar-content{
        overflow: hidden; 
        cursor: pointer;
        box-shadow: 0px 0px 10px -5px;
        border-radius: 4px; 
        position: relative;
        margin: auto;   
        padding: 0  ; 
        display: flex;  
        height: 30px;
        width: 98%;
        background-color: #7f9696ef;
        justify-content: initial;
        justify-items: initial;
        align-items: initial;
      }
    
    .main-placeholder {
        overflow: hidden;
        position: relative;
    }
}

  */  