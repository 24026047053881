body {
  overflow-x: hidden;
  width: 100%;
  background-color: #fff;
  background-color: var(--mindai-layout-body-background-color);
  height: 100%;
  font-size: var(--mindai-section-base-font-size);
  font-weight: 400;
  line-height: var(--mindai-section-base-line_height);
  margin: 0;
  font-family:  var(--mindai-base-font-family-combination);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  min-block-size: 100vh;
  min-block-size: -webkit-fill-available;
  padding: 0;
} 
 
:root, [lang*=en], [lang*=en] body, html[lang*=en] {
  --mindai-section-font-family--combined: var(--mindai-base-font-family-combination)!important;
  font-family: var(--mindai-section-font-family--combined,system);
}

.mindai-template-landing-page--content-snapping-on{
 
  -webkit-perspective: 1px;
  perspective: 1px;
}

.mindai-layout-modal--open{
  block-size: 70%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  inline-size: 980px;
  left: 0;
  position: fixed;
  right: 0;
  background-color:#fff;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 0px 110px -60px  rgb(89, 89, 224);
  color: #393c39;
  max-block-size:  100%;
  overflow-y: auto;
  padding: 0;
  z-index: 1000;
  overscroll-behavior: contain;
}
.mindai-layout-modal-title{
  font-size: 24px;
  font-family: var(--mindai-base-font-family-combination);
  height: auto;
  position: relative;
  text-align: justify;
  font-weight: bold;
  font-style: red;
}
.mindai-layout-modal-paragraph-container{
  position: relative;
  padding-top: 8px;
  text-align: left;
}
.mindai-layout-modal-paragraph{
  font-family: var(--mindai-base-font-family-combination);
  height: auto;
  display: block;
  width: 100%;
  font-size: 14px;
  color: #5c5e62; 
}
.mindai-layout-modal-container{
  max-width: none; 
  position: relative;
}
.mindai-layout-modal-table{ 
  height: auto;
  width: 100%;
  border-radius: 4px;
  padding-bottom: 30px;  
}

.mindai-layout-modal-table-rows{
  position: relative; 
  block-size: 22px;
}
.mindai-layout-modal-table-rows-container{
  border-bottom-style:  solid;
  border-bottom-color:  #939aff ; 
  border-bottom-width: 0.5px ; 
  height: 100%;
  width: auto;
  text-align: center;
  font-weight: 500;
  font-family: var(--mindai-base-font-family-combination);
  font-size: 14px;
}
h1, h2, h3, h4, h5, h6, li, p, small {
  line-height: 20px;
}
h1, h2, h3, h4, h5, h6, p, small {
  display: block;
  margin: 0;
}

.mindai-section-shell{
  --mindai-section-shell-content-height: calc(100vh - var(--mindai-section-shell-header-height)- var(--mindai-section-shell-footer-height));
  grid-gap: 0 24px;  
  grid-template-areas: 
      /* "mindai-section-shell-header"  */
      "mindai-section-shell-content" 
      "mindai-section-shell-footer" ;
  grid-template-columns: auto; 
  grid-template-rows: var(--mindai-section-shell-header-height) 1fr var(--mindai-section-shell-footer-height);
  min-block-size: 100vh;
  min-block-size: -webkit-fill-available;
}  
.mindai-layout-page-shell{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap; 
  max-width: 100vw;
  overflow-x: hidden;
}

 

.mindai-section-social_summary{ 
  color: #393c41;
  height: auto;
  position: relative; 
}
.mindai-section-social-icon-resume {
  width: 30%;  
  font-family: 'Roboto'; 
  text-align: center;  
  border-radius: 4px;
  background-color: transparent;
  position: relative;  
  display: grid;
  grid-template-columns: 30px 30px 30px;  
  border-width: 4px; 
  padding: 0px 0px 24px;
  justify-content: center;
  align-items: center; 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  gap: 4px;
   
}
.mindai-section-social-icon-resume-item{
  overflow: hidden; 
  cursor: pointer;
  box-shadow: 0px 0px 10px -5px;
  border-radius: 4px; 
  position: relative;
  margin: auto;  
  justify-content: center; 
  background-color: #fff;
  padding: 0; 
  display: flex; 
  text-align: center;
  width: 26px ;
  height: auto;
}
 

.mindai-section-social-icon-resume_small-screen {
  width: 50%;  
  font-family: var(--mindai-base-font-family-combination); 
  text-align: center;  
  border-radius: 4px;
  background-color: transparent;
  position: relative;  
  display: flex;
  grid-template-columns: 30px 30px 30px 30px 30px 30px;  
  border-width: 4px; 
  padding: 0px 0px 24px;
  justify-content: center;
  align-items: center; 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  gap: 4px;
   
}
.mindai-section-social-icon-resume-item-resume_small-screen{
  overflow: hidden; 
  cursor: pointer;
  box-shadow: 0px 0px 10px -5px;
  border-radius: 4px; 
  position: relative;
  margin: auto;  
  justify-content: center; 
  background-color: #fff;
  padding: 0; 
  display: flex; 
  text-align: center;
  width: auto ;
  height: auto;
}
 
@media (max-width: 599px){
  .mindai-layout-modal-table{
    padding-top: 0 !important;
    padding-bottom: 18 !important;
  }
}
@media (min-width: 600px){
  .mindai-layout-modal--open[open] {
      transform: translateX(0) translateY(50%);
  }
  .mindai-layout-modal--open:not([open]) {
    transform: translateX(0) translateY(100%);
  }
}

.mindai-layout-modal--open:not([open]) {
  visibility: hidden;
}
.upsale-button {
  position: relative;
  z-index: 0;
  height: auto;
  overflow: hidden;
  border: none !important;
  padding: 0 !important;
  background: yellow !important;
  color: #c800ff;
  background: rgb(225, 0, 255);
  border-radius: 8px; 
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0 4px 50px 0 rgb(0 0 0 / 7%);
  transition: .2s all linear;
  text-decoration: initial;
  min-width: 260px;
  width: 100% !important;
}

.upsale-button div{
  position: relative;
  z-index: 1;
  height: calc( 100% - 4px );
  width: calc( 100% - 4px );
  top: 2px;
  left: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  color: white;
  background: black;
}

.upsale-button:after{
 content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(90deg, rgba(255,255,255,0)    0%, rgb(51, 0, 255) 50%, rgba(255,255,255,0) 100%);
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
}
@-webkit-keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}
.mindai-section-countdown{
  display: grid;
  padding-block-start: var(--mindai-section-size--1x);
  padding-block-end: var(--mindai-section-size--1x);
  grid-template-columns: auto auto auto auto auto auto auto;
  height: 48px;

}
.mindai-section-countdown-item-single{
  display: block; 
}
.mindai-section-countdown-item-double{
  display: grid;
  grid-template-rows: auto auto;

}
.mindai-layout-countdown-item-single-font{
  font-size: var(--mindai-section-size--4x);
  font-family: var(--mindai-base-font-family-combination);
  color: var(--mindai-layout-body-background-color);
  font-weight: 1000;
}
.mindai-layout-countdown-item-small-font{
  padding-top: 4px;
  font-size: 14px;
  font-family: var(--mindai-base-font-family-combination);
  color: var(--mindai-layout-body-background-color);
  font-weight: 800;
}
.mindai-layout-modal--open:not([open]) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity .5s var(--mindai-section-bezier),transform .5s var(--mindai-section-bezier),visibility 0s .5s;
}
.mindai-layout-modal--open[open] {
  opacity: 1;
  transform: translateX(0) translateY(0);
  pointer-events: inherit;
  visibility: inherit;
  transition: opacity .9s var(--mindai-section-bezier),transform .9s var(--mindai-section-bezier),visibility 0s 0s;
}
.mindai-section-card.mindai-section-locale-selector-country, 
.mindai-section-card.mindai-section-locale-selector-trigger {
  background-color: transparent;
  border-radius: 4px;
} 
.mindai-section-card {
  --mindai-section-card--cancel-padding: calc(24px * -1);
  --mindai-section-card--gap: 24px;
  --mindai-section-card--width: calc(75ch + 16px *  2);
  background-color: var(--mindai-section-color--grey70);
  block-size: -webkit-min-content;
  block-size: min-content;
  border-radius: 4px;
  display: flex;
  max-block-size: -webkit-min-content;
  max-block-size: min-content; 
  max-inline-size: var(--mindai-section-card--width);
  overflow: hidden;
  transition: background-color .33s ease;
}

.mindai-section-card.mindai-section-locale-selector-country, 
.mindai-section-card.mindai-section-locale-selector-trigger 
.mindai-section-card-body {
  padding: var(--mindai-section-size--1x) 12px;
}

.mindai-section-card-body {
  -webkit-padding-end: max(var(--mindai-section-size--3x),var(--mindai-section-card--padding));
  flex-grow: 1;
  padding: var(--mindai-section-card--padding);
  padding-inline-end: max(var(--mindai-section-size--3x),var(--mindai-section-card--padding));
}
.mindai-section-card>.mindai-section-icon+.mindai-section-card-body {
  -webkit-padding-before: calc(var(--mindai-section-card--padding) + 2px);
  -webkit-padding-start: calc(var(--mindai-section-card--padding)/3);
  padding-block-start: calc(var(--mindai-section-card--padding) + 2px);
  padding-inline-start: calc(var(--mindai-section-card--padding)/3);
}
.mindai-section-card.mindai-section-locale-selector-trigger .mindai-section-card-body {
  -webkit-padding-start: var(--mindai-section-size--1x);
  padding-inline-start: var(--mindai-section-size--1x);
}
.mindai-section-text--caption {
  -webkit-padding-before: 12px;
  color: var(--mindai-section-theme-foreground-low-contrast);
  font-size: var(--mindai-section-font-size--20);
  line-height: var(--mindai-section-line-height--10);
  padding-block-start: 12px;
}
.mindai-section-link--primary, .mindai-section-link--secondary {
  box-shadow: 0 0 0 0 transparent;
  font-weight: 500;
}
.mindai-section-link--secondary {
  color: var(--mindai-section-theme-foreground-low-contrast);
}
.mindai-section-locale-selector-trigger {
  background-color: transparent;
  cursor: pointer;
  display: flex;
  transition: background-color .33s ease;
}
.mindai-section---modal{
  flex-flow: initial;
  width: 100vw;
  padding-bottom: var(--mindai-section-size--4x);
  background: none;
  box-shadow: none;
  transform: none!important;
  height: 100dvh!important;
} 
@media (hover:hover){
  .mindai-section-site-nav-item:where(
    :not(.mindai-section-site-nav-item--read-only)
    ):hover{
      --mindai-section-nav-item--color:var(--mindai-section-theme-foreground-high-contrast);
      -webkit-backdrop-filter:blur(16px);
      backdrop-filter:blur(16px);
      background-color:rgba(0,0,0,.05);
    }.mindai-section-site-nav-item.mindai-section--highlighted:where(
        :not(.mindai-section-site-nav-item--read-only)
        :not(.mindai-section-animate--backdrop)
        ):hover{
          background-color:rgba(0,0,0,.075)
        }
  .mindai-section-card:where(
    :not(.mindai-section-site-nav-item--read-only)
    ):hover{
    background-color:rgba(0,0,0,.075);
  }.mindai-section-card:where(
    :not(.mindai-section-site-nav-item--read-only)
    :not(.mindai-section-animate--backdrop)
    ):hover{
      background-color:rgba(0,0,0,.075)
    }
}
@media(max-width: 599px){
  html.mindai-layout-hide-overflow, html.mindai-layout-hide-overflow body{
    height: 100%;
    margin: 0; 
  }
  .mindai-layout-countdown-item-single-font{
    font-size: var(--mindai-section-size--3x);
  }
}
[class*=mindai-section-scrim--] {
  background-color: var(--mindai-section-theme-background);
} 
:root{
  --mindai-layout-body-background-color: #fff;
  --mindai-section-base-font-size: 14px;
  --mindai-section-base-line_height: 20px;
  --mindai-base-font-family-combination: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,  'Helvetica Neue', sans-serif !important;
  --mindai-section-shell-header-height: 56px;
  --mindai-section-shell-footer-height: 52px;
  --mindai-layout-hero-block-size: 100vh;
  --mindai-layout-hero-inline-size: 100%;
  --mindai-layout-component-padding-desktop-block-start: 0;
  --mindai-layout-component-padding-desktop-block-end: 0;
  --mindai-layout-image-overflow: visible;
  --mindai-layout-image-position: relative;
  --mindai-layout-image-inline-size: 100%;
  --mindai-section-foreground-high-contrast: #fff; 
  --mindai-section-theme-foreground: #393c41; 
  --mindai-section-theme-foreground-high-contrast:#171a20;
  --mindai-section-theme-foreground-low-contrast:#5c5e62;
  --mindai-section-color--red20: #ed4e3b;
  --mindai-section-color-black: #000;
  --mindai-section-theme-primary: #3e6ae1;
  --mindai-section-color--grey10: #171a20;
  --mindai-section-color--grey70: #f4f4f4;
  --mindai-section-color--white: #fff;
  --mindai-section-color-white: #fff;
  --mindai-section-padding-size--3x: 24px;
  --mindai-section-padding-size--1x: 8px;
  --mindai-layout-item-inline-size: calc(8px * 20);
  --mindai-section-bezier: cubic-bezier(0.5,0,0,0.75);
  --mindai-section-size--half: 4px; 
  --mindai-section-size--1x: 8px;
  --mindai-section-size--2x: 16px;
  --mindai-section-size--3x: 24px;
  --mindai-section-size--4x: 32px;
  --mindai-section-size--5x: 40px;
  --mindai-section-size--6x: 48px;
  --mindai-section-size--7x: 56px;
  --mindai-section-size--8x: 64px;
  --mindai-section-size--9x: 72px;
  --mindai-section-size--10x: 80px;
  --mindai-section-size--11x: 88px;
  --mindai-section-size--12x: 96px;
  --mindai-section-size--13x: 104px;
}

.mindai-section-color-scheme__dark{
  --mindai-section-box-shadow--large: 0 8px 16px 0 rgba(0,0,0,.8);
  --mindai-section-box-shadow--medium: 0 8px 16px 0 rgba(0,0,0,.6);
  --mindai-section-box-shadow--small: 0 4px 8px 0 rgba(0,0,0,.4);
  --mindai-section-color--error: var(--mindai-section-color--red20); 
  --mindai-section-color--inverse: var(--mindai-section-color-black);
  --mindai-section-color--rgb-shade: 255,255,255;
  --mindai-section-color--rgb-shade--inverse: 0,0,0;
  --mindai-section-color--scrim--semitransparent: rgba(0,0,0,.7);  
  color: var(--mindai-section-foreground-high-contrast);
}