#mindai-section-site-header-wrapper 
.mindai-section-modal.mindai-section-site-header-modal {
  --mindai-section-modal--height: 100%;
  z-index: 1000000;
  will-change: scroll-position;
}

/* 

.mindai-section-modal--sheet-right:not([open]), [dir=rtl] .mindai-section-modal--sheet-left:not([open]) {
  -webkit-transform: translateX(50%) translateY(0);
  transform: translateX(50%) translateY(0);
}
.mindai-section-modal--fullscreen:not([open]),
.mindai-section-modal:not([open]) {
  -webkit-transform: translateX(0) translateY(50%);
  transform: translateX(0) translateY(50%);
}
.mindai-section-modal:not([open]) {
  opacity: 0;
  pointer-events: none;
  transition: opacity .5s var(--mindai-section-bezier),visibility 0s .5s,-webkit-transform .5s var(--mindai-section-bezier);
  transition: opacity .5s var(--mindai-section-bezier),transform .5s var(--mindai-section-bezier),visibility 0s .5s;
  transition: opacity .5s var(--mindai-section-bezier),transform .5s var(--mindai-section-bezier),visibility 0s .5s,-webkit-transform .5s var(--mindai-section-bezier);
  visibility: hidden;
}
.mindai-section-modal[open] {
  opacity: 1;
  pointer-events: inherit;
  -webkit-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
  transition: opacity .5s var(--mindai-section-bezier),visibility 0s 0s,-webkit-transform .5s var(--mindai-section-bezier);
  transition: opacity .5s var(--mindai-section-bezier),transform .5s var(--mindai-section-bezier),visibility 0s 0s;
  transition: opacity .5s var(--mindai-section-bezier),transform .5s var(--mindai-section-bezier),visibility 0s 0s,-webkit-transform .5s var(--mindai-section-bezier);
  visibility: inherit;
}
.mindai-section-site-header-modal {
  -webkit-padding-after: var(--mindai-section-size--6x);
  padding-block-end: var(--mindai-section-size--6x);
} 
 
.mindai-section-modal--sheet-right, [dir=rtl] .mindai-section-modal--sheet-left {
  left: auto;
  right: 0;
} 
.mindai-section-modal--sheet-small {
  --mindai-section-modal--width: 311px;
}
.mindai-section-modal--sheet-left, .mindai-section-modal--sheet-right {
  --mindai-section-modal--width: 414px;
  max-inline-size: calc(100vw - 48px);
}
.mindai-section-modal--fullscreen,
.mindai-section-modal--sheet-left, 
.mindai-section-modal--sheet-right{
  --mindai-section-modal--height: 100%;
  --mindai-section-modal--height: 100dvh;
  border-radius: 0;
  bottom: 0;
  max-block-size: 100%; 
  top: 0;
}
.mindai-section-modal {
  --mindai-section-modal-border-radius: var(--mindai-section-size--1x);
  -ms-scroll-chaining: none;
  background-color: var(--mindai-section-foreground-high-contrast);
  block-size: 100%;
  border: none;
  border-radius: var(--mindai-section-size--half) var(--mindai-section-size--half) 0 0;
  bottom: 0;
  box-shadow: 0 -8px 16px rgba(0, 0, 0, .16);
  color: var(--mindai-section-theme-foreground);
  display: flex;
  flex-direction: column;
  inline-size: var(--mindai-section-modal--width);
  left: 0;
  max-block-size: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
  padding: 0;
  position: fixed;
  right: 0;
  z-index: 1000;
}
 

.mindai-section-modal:not([open]) {
  visibility: hidden;
}
  
.mindai-section-modal:not([open]) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity .5s var(--mindai-section-bezier),transform .5s  var(--mindai-section-bezier),visibility 0s .5s;
}

.mindai-section-modal[open]{
  opacity: 1;
  transform: translateX(0) translateY(0);
  pointer-events: inherit;
  visibility: inherit; 
  transition: opacity .8s cubic-bezier(0.5,0,0,0.75),transform .8s cubic-bezier(0.5,0,0,0.75),visibility .8s;
} 
.mindai-section-scrim--white {
  --mindai-section-dialog--background-color: var(--mindai-section-color-white);
  --mindai-section-scrim--background: var(--mindai-section-color-white);
}
#mindai-section-site-header-wrapper 
.mindai-section-site-header-modal 
.mindai-section-modal-header{
  min-block-size: 80px;
}

.mindai-section-site-header-modal .mindai-section-modal-header {
  padding: 20px;
}

.mindai-section-modal-header {
  -webkit-padding-start: var(--mindai-section-size--3x);
  align-items: flex-start;
  background-color: var(--mindai-section-theme-background-dialog);
  box-shadow: 0 -2px 0 0 var(--mindai-section-theme-background-dialog);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: var(--mindai-section-size--2x);
  padding-inline-start: var(--mindai-section-size--3x);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.mindai-section-modal-close {
  -webkit-appearance: none;
  appearance: none;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: transparent;
  border: none;
  border-radius: var(--mindai-section-border-radius--pill);
  cursor: pointer;
  outline: none;
  padding: var(--mindai-section-size--1x);
  transition: background-color .33s ease;
  z-index: 10;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}

button {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  padding: 0;
}
button, select {
  text-transform: none;
}
button, input {
  overflow: visible;
}
button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
}
*/
@media (min-width: 600px){
  .mindai-section-modal--sheet-small .mindai-section-modal-content,
   .mindai-section-modal--sheet-small .mindai-section-modal-footer {
      -webkit-padding-start: var(--mindai-section-size--4x);
      -webkit-padding-end: var(--mindai-section-size--4x);
      padding-inline-end: var(--mindai-section-size--4x);
      padding-inline-start: var(--mindai-section-size--4x);
  }
}
.mindai-section-modal-header+.mindai-section-modal-content {
  -webkit-padding-before: var(--mindai-section-size--half);
  padding-block-start: var(--mindai-section-size--half);
}
@media (min-width: 600px){
  .mindai-section-modal-content, .mindai-section-modal-footer {
      -webkit-padding-end: var(--mindai-section-size--6x);
      padding-inline-end: var(--mindai-section-size--6x);
  }
}
@media (min-width: 600px){
  .mindai-section-modal-content, .mindai-section-modal-footer, .mindai-section-modal-header {
      -webkit-padding-start: var(--mindai-section-size--6x);
      padding-inline-start: var(--mindai-section-size--6x);
  }
}
.mindai-section-modal-content {
  padding: 0 var(--mindai-section-size--3x) var(--mindai-section-size--6x);
}
/*

.mindai-section-site-nav-items--vertical {
  align-items: normal;
  flex-direction: column;
}
.mindai-section-site-nav-items {
  align-items: center;
  display: flex;
  margin: 0;
  position: relative;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease,-webkit-transform .5s ease;
  z-index: 1;
}

dd, dl, dt, figcaption, figure, ol, ul {
  margin: 0;
  padding: 0;
}
dd, dl, dt, ol, ul {
  list-style-type: none;
}
*/
#mindai-section-site-header-wrapper .mindai-section-site-nav-item {
  --mindai-section-nav-item--color: var(--mindai-section-theme-foreground);
}

.mindai-section-site-nav-items--vertical .mindai-section-site-nav-item {
  inline-size: 100%;
}
.mindai-section-site-nav-item {
  --mindai-section-nav-item--padding: var(--mindai-section-size--1x);
  --mindai-section-nav-item--width: auto;
  align-items: center;
  border-radius:4px;
  color: #393c41;
  display: inline-flex;
  font-weight: 500;
  inline-size: auto;
  min-block-size: 32px;
  padding: var(--mindai-section-size--half) var(--mindai-section-size--1x);
  transition: color .33s ease,background-color .33s ease;
}
.mindai-section-site-nav-items--vertical .mindai-section-site-nav-item-text {
  flex-grow: 1;
  margin: 0 var(--mindai-section-size--half);
  text-align: start;
}
.mindai-section-site-nav-item-text {
  color: #393c41;
  margin: 0 var(--mindai-section-size--1x);
  transition: color .33s ease;
}
/*
@media (min-width: 600px){
  .mindai-section-modal {
    --mindai-section-modal-border-radius: var(--mindai-section-size--2x);
    border-radius: var(--mindai-section-size--2x);
    bottom: 50%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, .16);
    margin: auto;
    max-block-size:96px;
    max-inline-size: 96px;
    min-block-size: 64px;
}
  .mindai-section-modal[open] {
    -webkit-transform: translateX(0) translateY(50%);
    transform: translateX(0) translateY(50%);
  }
  .mindai-section-modal[open] {
    transform: translateZ(0) translateX(50%); 
  }
  .mindai-section-modal--sheet-right:not([open]),
   [dir=rtl] .mindai-section-modal--sheet-left:not([open]) {
    -webkit-transform: translateX(50%) translateY(0);
    transform: translateX(50%) translateY(0);
  }
  .mindai-section-modal--fullscreen[open], .mindai-section-modal--sheet-left[open],
  .mindai-section-modal--sheet-right[open] {
     -webkit-transform: translateX(0) translateY(0);
     transform: translateX(0) translateY(0);
 }
 .mindai-section-modal--fullscreen, 
 .mindai-section-modal--sheet-left, 
 .mindai-section-modal--sheet-right {
  border-radius: 0;
  bottom: 0;
  max-block-size: 100vh;
}
  .mindai-section-modal:not([open]) {
    transform: translateZ(0) translateX(100%);
  }
  .mindai-section-modal--sheet-right:not([open]), 
  [dir=rtl] .mindai-section-modal--sheet-left:not([open]) {
    -webkit-transform: translateX(50%) translateY(0);
    transform: translateX(50%) translateY(0);
  }
  */
  .mindai-section-modal-content, 
  .mindai-section-modal-footer,
   .mindai-section-modal-header {
    -webkit-padding-start: var(--mindai-section-size--6x);
    padding-inline-start: var(--mindai-section-size--6x);
  }
/*
  .mindai-section-modal--fullscreen,
  .mindai-section-modal--sheet-left,
  .mindai-section-modal--sheet-right {
    border-radius: 0;
    bottom: 0;
    max-block-size: 100vh;
  }
} */
 
#mindai-section-site-header-wrapper 
.mindai-section-site-header-modal 
.mindai-section-card.mindai-section-locale-selector-trigger .mindai-section-icons {
  -webkit-margin-before: 8px;
  margin-block-start: 8px;
  -webkit-margin-before: var(--mindai-section-size--1x);
  margin-block-start: var(--mindai-section-size--1x);
  -webkit-margin-start: 8px;
  margin-inline-start: 8px;
  -webkit-margin-start: var(--mindai-section-size--1x);
  margin-inline-start: var(--mindai-section-size--1x);
  padding: 1px;
}
.mindai-section-card.mindai-section-locale-selector-trigger .mindai-section-icons {
  -webkit-margin-before: calc(var(--mindai-section-size--1x) - 2px);
  -webkit-margin-start: 12px;
  margin-block-start: calc(var(--mindai-section-size--1x) - 2px);
  margin-inline-start: 12px;
}
:is(.mindai-section-card,.mindai-section-card--asset)>.mindai-section-icons {
  -webkit-margin-after: var(--mindai-section-card--padding);
  -webkit-margin-before: var(--mindai-section-card--padding);
  -webkit-margin-start: var(--mindai-section-card--padding);
  margin-block-end: var(--mindai-section-card--padding);
  margin-block-start: var(--mindai-section-card--padding);
  margin-inline-start: var(--mindai-section-card--padding);
}
.mindai-section-icons {
  background: var(--mindai-section-icon--bg);
  block-size: 24px;
  content: "";
  display: block;
  inline-size: var(--mindai-section-icon--size);
  max-inline-size: 100%;
  min-block-size: var(--mindai-section-icon--size);
  min-inline-size: var(--mindai-section-icon--size);
  overflow: hidden;
  text-indent: -9999px;
  transition: color .33s ease,-webkit-transform .5s var(--mindai-section-bezier);
  transition: color .33s ease,transform .5s var(--mindai-section-bezier);
  transition: color .33s ease,transform .5s var(--mindai-section-bezier),-webkit-transform .5s var(--mindai-section-bezier);
}
.menumodal {
  --menumodal-border-radius: 8px;
  block-size: 100vh;
  bottom: 0;
  display: flex;
  flex-direction: column;
  inline-size: 350px;
  left: 0;
  position: fixed;
  right: 0;
  background-color:#fff;
  border: none;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 -8px 16px 0 rgba(0,0,0,0.16);
  color: #393c39;
  max-block-size:  100%;
  overflow-y: auto;
  padding: 0;
  z-index: 1000;
  overscroll-behavior: contain;
}

.menumodal .mindai-section-site-header-modal{
  --mindai-section-modal-height: 100%;
  z-index: 1000000;
  will-change: scroll-position;
}
@media (max-width: 599px){
  .menumodal[open] {
      transform: translateZ(0) translateX(50%); 
  }
  .menumodal:not([open]) {
    transform: translateZ(0) translateX(100%);
  }
}
@media (min-width: 600px){
  .menumodal[open] {
      transform: translateZ(0) translateX(50%); 
  }
  .menumodal:not([open]) {
    transform: translateZ(0) translateX(100%);
  }
}


.menumodal:not([open]) {
  visibility: hidden;
}

.menumodal:not([open]) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity .5s cubic-bezier(0.5,0,0,0.75),transform .5s cubic-bezier(0.5,0,0,0.75),visibility 0s .5s;
}


.menumodal[open] {
  opacity: 1;
  transform: translateX(0) translateY(0);
  pointer-events: inherit;
  visibility: inherit; 
  transition: opacity .8s cubic-bezier(0.5,0,0,0.75),transform .8s cubic-bezier(0.5,0,0,0.75),visibility .8s;
}


.mindai-section-site-header-modal {
  -webkit-padding-after: 48px;
  padding-block-end: 48px;
}

.menumodal--sheet-right, [dir=rtl] .menumodal--sheet-left {
  left: auto;
  right: 0;
}

.menumodal--sheet-small {
  --menumodal--width: 350px;
}
.menumodal--sheet-left, .menumodal--sheet-right {
  --menumodal--width: 414px;
  max-inline-size: calc(100vw - 48px);
}
.abf01c--white {
  --abf01c-dialog--background-color: #fff;
  --abf01c-scrim--background: #fff;
}


.site-header-menumodal .menumodal-header {
  padding: 20px;
}

.menumodal-header {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 16px;
  -webkit-padding-start: 24px;
  padding-inline-start: 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}


.menumodal-content:last-child {
  padding-bottom: 0;
}

.menumodal-content{
  padding-top: 34px;
 
}

.menumodal-content {
  -webkit-padding-start: 64px;
  padding-inline-start: 64px;
  -webkit-padding-end: 64px;
  padding-inline-end: 64px;
  overflow-y: scroll;
}
 

.locale-slctor {
  display: flex;
  inline-size: 100%;
  justify-content: center;
  margin: auto;
  max-inline-size: 1260px;
  padding: 0 16px;
}
.locale-slctor--narrow {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}

.locale-slctor-column {
  inline-size: 100%;
  margin: 0 auto;
  max-inline-size: 700px;
}

.locale-slctor-superregion {
  padding: 0 12px 24px;
}

.locale-slctor--narrow .locale-slctor-region {
  -moz-column-count: 1;
  column-count: 1;
}

.locale-slctor-region {
  -moz-column-count: 2;
  column-count: 2;
  -moz-column-gap: 16px;
  column-gap:16px;
  -webkit-margin-end: -12px;
  margin-inline-end: -12px;
  -webkit-margin-start: -12px;
  margin-inline-start: -12px;
}

.menumodal-content {
  padding: 0 24px 48px;
}
 


.menumodal-close-icon {
  display: block;
  height: 16px;
  width: 16px;
   background-repeat: no-repeat;
  background-position: 50%;
}

.closemodal:hover,
.closemodal:focus {
  color: black;
  animation-duration: 4s;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: relative;
  top: 20px;
  right: 25%;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: rgba(0,0,0,0.4);

}
  
