.mindai-layout-page-shell .mindai-layout-footer {
  padding: 0 24px;
}
 

@media (max-width: 599px), (min-width: 600px) and (max-width: 899px){
    .mindai-layout-page-main-content+.mindai-layout-footer {
    display: none;
  }
}

.mindai-section-footer{
  width: 100%;
  height: auto;
}
.mindai-section-site-footer {
  font-size: 12px;
  inline-size: 100%; 
}
.mindai-section-shell-footer{
  grid-area: shell-footer;
  width: 100%;
  max-height: 100%;
  height: auto;

}
  
.mindai-section-theme--marketing, .mindai-section-theme--marketing .mindai-section-colorscheme--light, .mindai-section-theme--marketing .mindai-section-scrim--light,
.mindai-section-theme--marketing .mindai-section-scrim--white, .mindai-section-theme--marketing.mindai-section-colorscheme--light,
.mindai-section-theme--marketing.mindai-section-scrim--light, .mindai-section-theme--marketing.mindai-section-scrim--white {
  --color--primary: #171a20;
  --color--primary-highlight: #393c41;
  --theme-foreground-on-primary: var(--mindai-section-foreground-high-contrast);
  --theme-primary: #171a20;
  --theme-primary-highlight: #393c41;
}


.mindai-section-scrim--white{
  --background-color: var(--mindai-section-foreground-high-contrast);
  --background: var(--mindai-section-foreground-high-contrast);
}

[class*=mindai-section-scrim--] {
  background-color: var(--mindai-section-foreground-high-contrast);
}

.mindai-section-footer-nav{
  -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    height: auto;
    width: 100%;
}

.mindai-section-footer-nav {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.mindai-section-footer-nav{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.mindai-section-footer-nav>ul {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.mindai-section-site-footer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: block !important;
  min-height: 70px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: 100%;
}
.mindai-section--align_center {
  display: block!important;
  margin: 0 auto!important;
 
}
.mindai-section-footer-nav .mindai-section-footer-item {
  color: #5c5e62;
  font-family: var(--mindai-base-font-family-combination);
  font-weight: 500;
}

.-footer-item{
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  margin: auto;
}
.mindai-section-list {
  --list--separator-color: #d0d1d2;
  --list--timeline-color: #393c41;
  --list-item--margin-block: 8px;
  --list-item--padding-inline: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.mindai-section-footer-item .mindai-section-link:not(.--disabled):not(.mindai-section-link--primary) {
  box-shadow: none;
  color: #5c5e62;
}

.mindai-section-site-footer .mindai-layout-link {
  white-space: nowrap;
}

.mindai-section-link { 
  background-color: transparent;
  border: 0;
  box-shadow: 0 1px 0 0 currentColor;
  color: #393c41;
  cursor: pointer;
  font-family: var(--mindai-base-font-family-combination);
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
  border-width: 4px;  
  padding: 8px;
  font-size: 14px;
  transition: box-shadow .33s var(--cubic-bezier),color .33s ease; 
 
}



.mindai-section-footer-item--center{
  color: #393c41;
  height: auto;
  position: relative; 
}

.mindai-section-footer-item-flex{

  width: 100%;  
  font-family: var(--mindai-base-font-family-combination); 
  text-align: center;  
  border-radius: 4px;
  background-color: transparent;
  position: relative;  
  display: flex; 
  border-width: 4px; 
  padding: 0px 0px 4px;
  justify-content: center;
  align-items: center; 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  gap: 4px;
}
.mindai-section-footer--nav--item {  
   
  width: 50%;  
  font-family: var(--mindai-base-font-family-combination); 
  color: #393c41; 
  text-align: center;  
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;  
  background-color: transparent;
  position: relative;  
  display: flex;  
  padding-inline-start: 0px;
  border-width: 4px;  
  justify-content: center;
  align-items: center; 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  gap: 4px; 
  transition: color .33s ease,background-color .33s ease;
}
.mindai-section-footer-group-grid{
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 15px;
  margin-top: 1em;
}

.mindai-section-footer-group-flex{
  height: auto;
  width: 100%;
  display: block; 
  padding: 15px; 
}

.mindai-section-footer--nav-items--vertical {
  align-items: normal;
  flex-direction: column;
  
}
.mindai-sectionfooter--nav--item {  
 
  width: 50%;  
  font-family: var(--mindai-base-font-family-combination); 
  color: #393c41; 
  text-align: center;  
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;  
  background-color: transparent;
  position: relative;  
  display: flex;  
  padding-inline-start: 0px;
  border-width: 4px;  
  justify-content: center;
  align-items: center; 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  gap: 4px; 
  transition: color .33s ease,background-color .33s ease;
}

.mindai-section-footer--nav--items-container{
  height: auto;
  width: 100%; 
  position: relative; 
  text-decoration: none;
}

.mindai-section-disclaimer-description { 
  background-color: transparent;
  border: 0; 
  color: #393c41; 
  font-family: var(--mindai-base-font-family-combination);
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 100%;
  height: auto;  
  font-size: 14px;
  transition: box-shadow .33s var(--cubic-bezier),color .33s ease; 
 
} 


@media screen and (min-width: 1200px) { 
 
  
  .mindai-section-page-shell .mindai-layout-footer {
    padding: 0 24px;
  }
  .mindai-layout-page-shell {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    max-width: 100vw;
    overflow-x: hidden;
  }
  
 
  
  .mindai-section-footer{
    width: 100%;
    height: auto;
  }
  .mindai-section-footer {
    font-size: 12px;
    inline-size: 100%; 
  }
  .mindai-section-shell-footer {
    grid-area: shell-footer;
    width: 100%;
    max-height: 100%;
    height: auto;
  
  }
   
   
  .mindai-section-scrim--white{
    --background-color: var(--mindai-section-foreground-high-contrast);
    --background: var(--mindai-section-foreground-high-contrast);
  }
  
  [class*=mindai-section-scrim--] {
    background-color: var(--mindai-section-foreground-high-contrast);
  }
  
  .mindai-section-footer-nav{
    -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column;
      flex-flow: column;
      height: auto;
      width: 100%;
  }
  
  .mindai-section-footer-nav {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .mindai-section-footer-nav{
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .mindai-section-footer-nav>ul {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .mindai-section-site-footer {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: block !important;
    min-height: 70px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: 100%;
  }
  .mindai-section--align_center {
    display: block!important;
    margin: 0 auto!important;
   
  }
  .mindai-section-footer-nav .mindai-section-footer-item {
    color: #5c5e62;
    font-family: var(--mindai-base-font-family-combination);
    font-weight: 500;
  }
  
  .mindai-section-footer-item{
    align-items: center;
    justify-content: center;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
  }
  .mindai-section-list {
    --list--separator-color: #d0d1d2;
    --list--timeline-color: #393c41;
    --list-item--margin-block: 8px;
    --list-item--padding-inline: 0;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .mindai-section-footer-item .mindai-section-link:not(.mindai-section--disabled):not(.mindai-section-link--primary) {
    box-shadow: none;
    color: #5c5e62;
  }
  
  .mindai-section-site-footer .mindai-layout-link {
    white-space: nowrap;
  }
  
  .mindai-section-s-link { 
    background-color: transparent;
    border: 0;
    box-shadow: 0 1px 0 0 currentColor;
    color: #393c41;
    cursor: pointer;
    font-family: var(--mindai-base-font-family-combination);
    padding: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 100%;
    height: 100%;
    border-width: 4px;  
    padding: 8px;
    font-size: 14px;
    transition: box-shadow .33s var(--cubic-bezier),color .33s ease; 
   
  }
  
  
  
  .mindai-section-footer-item--center{
    color: #393c41;
    height: auto;
    position: relative; 
  }
  
  .mindai-section-footer-item-flex{
  
    width: 30%;  
    font-family: var(--mindai-base-font-family-combination); 
    text-align: center;  
    border-radius: 4px;
    background-color: transparent;
    position: relative;  
    display: flex; 
    border-width: 4px; 
    padding: 0px 0px 4px;
    justify-content: center;
    align-items: center; 
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    gap: 4px;
  }
  
  .mindai-section-footer-group-grid{
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 15px;
    margin-top: 1em;
  }
  
  .mindai-section-footer-group-flex{
    height: auto;
    width: 100%;
    display: block; 
    padding: 15px; 
  }
  
  .mindai-section-footer--nav-items--vertical {
    align-items: normal;
    flex-direction: column;
    
  }
  .mindai-section-footer--nav--item {  
   
    width: auto;  
    font-family: var(--mindai-base-font-family-combination); 
    color: #393c41; 
    text-align: center;  
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;  
    background-color: transparent;
    position: relative;  
    display: flex;  
    padding-inline-start: 0px;
    border-width: 4px;  
    justify-content: center;
    align-items: center; 
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    gap: 4px; 
    transition: color .33s ease,background-color .33s ease;
  }
  
  .mindai-section-footer--nav--items-container{
    height: auto;
    width: 100%; 
    position: relative; 
    text-decoration: none;
  }
  
  .mindai-section-disclaimer-description { 
    background-color: transparent;
    border: 0; 
    color: #393c41; 
    font-family: var(--mindai-base-font-family-combination);
    padding: 8px 0px ;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 100%;
    height: auto;  
    font-size: 14px;
    transition: box-shadow .33s var(--cubic-bezier),color .33s ease; 
   
  }
  
  @media (min-width: 600px) and (max-width: 899px){
    .mindai-section-link{
      
      font-size: 12px;
    }
    .mindai-section-footer--nav--item{
      width: auto;
    }
  }
}