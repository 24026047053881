.mindai-section-menu-header-transparent--dark #mindai-section-site-header-wrapper {
  --mindai-section-nav-item--color: var(--mindai-section-color--grey10);
  --mindai-section-nav-item--color-highlighted: var(--mindai-section-color--grey10);
  --mindai-section-icon-fill-secondary: var(--mindai-section-color--grey10);
  --mindai-section-theme-foreground: var(--mindai-section-color--grey10);
  --mindai-section-theme-foreground-high-contrast: var(--mindai-section-color--grey10);
  --mindai-section-pill--blur-color: hsla(0,0%,0%,.05);
}
.mindai-section-site-header--dark #mindai-section-site-header-wrapper {
  --mindai-section-nav-item--color: var(--mindai-section-color--grey70);
  --mindai-section-nav-item--color-highlighted: var(--mindai-section-color--white);
  --mindai-section-icon-fill-secondary: var(--mindai-section-color--white);
  --mindai-section-theme-foreground: var(--mindai-section-color--grey10);
  --mindai-section-theme-foreground-high-contrast: var(--mindai-section-color--grey10);
  --mindai-section-pill--blur-color: hsla(0,0%,0%,.05);
}
.mindai-section-menu-header-transparent--dark #mindai-section-site-header-wrapper, 
.mindai-section-menu-header-transparent--light #mindai-section-site-header-wrapper {
  position: absolute;
}

#mindai-section-site-header-wrapper {
  width: 100vw;
}
.template-landing-page--content-snapping-on.mindai-section-menu-header-transparent--dark 
.mindai-section-site-header-wrapper, 
.template-landing-page--content-snapping-on.mindai-section-menu-header-transparent--light
 .mindai-section-site-header-wrapper {
  top: 0; 
}
.mindai-section-global-menu, .mindai-section-site-header {
  inline-size: 100%;
}

.mindai-section-header-content{ 
  width: 100%;
  z-index: 5;
  position: sticky;
  top: 0; 
} 
.mindai-section-fade-in{
  -webkit-animation: 1s ease;
  animation: 1s ease
}
 
.mindai-section-site-header{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: relative;
  z-index: 10;
}
.mindai-section-site-header>.mindai-section-site-logo {
  min-block-size: 56px;
} 

.mindai-section-site-header .mindai-section-align--start {
  -webkit-padding-start: var(--mindai-section-size--1x);
  justify-content: flex-start;
  padding-inline-start: var(--mindai-section-size--1x);
}

h1.mindai-section-site-logo {
  padding: 0;
  position: relative;
}
.mindai-section-text--caption-header,
  .mindai-section-text--h1, .mindai-section-text--h1-alt,
  .mindai-section-text--h2, .mindai-section-text--h3,
  .mindai-section-text--h4, .mindai-section-text--h5,
  .mindai-section-text--h6, h1, h2, h3, h4, h5, h6 {
  -webkit-padding-before: var(--mindai-section-size--4x);
  -webkit-padding-after: var(--mindai-section-size--1x);
  color: var(--mindai-section-theme-foreground-high-contrast);
  font-family: var(--mindai-base-font-family-combination);
  font-weight: 500;
  letter-spacing:-0.6px;
  padding-block-end: var(--mindai-section-size--1x);
  padding-block-start: var(--mindai-section-size--4x);
  text-transform: none;
  transition: font .33s ease,color .33s ease,opacity .33s ease,padding .33s ease;
}
.mindai-section-site-logo{
  align-items: center;
  display: flex;
}


.mindai-section--site-logo-link{
  align-items: center;
  display: inline-flex;
  min-block-size: var(--mindai-section-size--4x);
}
.mindai-section-site-logo-link {
  box-shadow: none;
  padding: 0 var(--mindai-section-size--2x);
}
 
#mindai-section-site-header-wrapper .mindai-section-site-logo-icon {
  color: var(--mindai-section-theme-foreground);
}

.mindai-section-icon {
  background: transparent;
  content: "";
  display: block;
  block-size: 64px; 
  max-inline-size: 100%;
  min-block-size: 56px;
  min-inline-size: 56px;    
  overflow: hidden;
  text-indent: -9999px;
  transition: color .33s ease,-webkit-transform .5s var(--mindai-section-bezier);
  transition: color .33s ease,transform .5s var(--mindai-section-bezier);
  transition: color .33s ease,transform .5s var(--mindai-section-bezier),-webkit-transform .5s var(--mindai-section-bezier);
}
.mindai-section-icon-logo-wordmark {
  inline-size: 100%;
}
.mindai-section-site-header>.mindai-section-site-nav-items {
  min-block-size: 56px;
  position: static;
}



@media (min-width: 600px){
  .mindai-section-site-header .mindai-section-align--start {
      -webkit-padding-start: 20px;
      padding-inline-start: 20px;
  }
  .mindai-section-text--h1, .mindai-section-text--h1-alt, h1 {
    --mindai-section-heading--letter-spacing: -0.6px;
  }
  .mindai-section-text--h1, h1 {
    font-size:  40px;
    line-height:  48px;
  }
}
@media (min-width: 900px){
  :is(h1,.mindai-section-text--h1,.mindai-section-text--h1-alt) {
    --mindai-section-spacer-top: calc(4px*18);
  }
}
@media (min-width: 1199px){
  #mindai-section-site-header-wrapper 
  .mindai-section-site-header 
  .mindai-section-align--start, #mindai-section-site-header-wrapper 
  .mindai-section-site-header .mindai-section-align--end {
      min-inline-size: 240px;
  }
}
@media (min-width: 1200px){
  .mindai-section-site-header .mindai-section-align--start {
      -webkit-padding-start: var(--mindai-section-size--4x);
      flex-basis: 0;
      flex-grow: 1;
      padding-inline-start: var(--mindai-section-size--4x);
  }
} 




.site-header{
  background: hsla(0,0%,100%,.75);
  backdrop-filter: blur(48px);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: relative;
  z-index: 99;
  block-size: 76px;
  max-width: 100%;

}
 
.site-header>.site-logo{
  min-block-size: 56px;
}  

.site-header .align--start{
  justify-content: flex-start;
}

::-webkit-scrollbar{
  width: 0%;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
  
} 
h1.site-logo{
  padding:  0;
  position: relative;
  width: 172px;
}

.site-logo{
  align-items: center;
  display: flex; 
  left: 0; 
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;

}
.dccea7722760{  
  margin-left: 25px;
  
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

svg{
  transform: translateZ(0);
}

.logo-icon {
  background: transparent;
  block-size: 64px;
  content: "";
  display: block;
  max-inline-size: 100%;
  min-block-size: 55px;
  min-inline-size: 55px;
  overflow: hidden;
  text-indent: -9999px; 
}

.icon-logo-mark {
  inline-size: 100%;
}


.logo-link{ 
  display: inline-flex;
  padding: 0;
  width: 176px;
  
} 

/* 
 .site-logo{
  height: 70px;
  width: 100%;
  position: relative;

} */


.site-header > .c0c0ef22304d{
  min-height: 54px;
  position: static;
}
.site-header .aebe8bd3ea {
  justify-content: flex-end;
 
}
.c0c0ef22304d {
  border-radius: 4px;
}
button{
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.site-nav-item-text{
  color: #171a20;
  font-size: 18px;
  font-weight: 500;
  margin: 0 8px;
  transition: color .33s ease;
}

.c0c0ef22304d {
  --item--padding:8px;
  --item--width: auto;
  align-items: center;
  display: inline-flex;
  font-weight: 500;
  inline-size: var(--item--width);
  min-block-size:32px;
  padding: 4px 8px;
  backdrop-filter: blur(16px);
  background-color:rgba(235, 224, 224, 0.75);
  transition: color .33s ease,background-color .33s ease;
}
 

.icon9cfb{
  background: transparent;
  block-size: 24px;
  content: "";
  display: block;
  inline-size: 24px;
  max-inline-size: 100%;
  min-block-size: 24px;
  min-inline-size: 24px;
  overflow: hidden;
  text-indent: -9999px;
}

.e60bc4acb94 {
  white-space: nowrap;
}

 
ol, ul {
  list-style-type: none;
}

/* ol {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
} */
.site-header> .e46069942{
    min-height: 54px;
    position: static;
    
}
.e46069942{
  align-items: center;
  display: flex;
  margin: 0;
  transition: transform .5s ease;
  position: relative;
  z-index: 1;
  margin-right: 5%;
  margin-bottom: 5px;
  
}



.logo::after{
  content: "";
  clear: both;
  display: table;
}
.primary-header{
  align-items: center;
  justify-content: space-between;
}

.primary-navigation{
  position: fixed;
  padding: 5%;
  list-style: none;
  padding: 20px;
  margin: 0;
  height: 5%;
  top: 0;
  right: 0%;
  

}
.primary-navigation a{
  text-decoration: none;
}
.primary-navigation a > span{
  text-decoration: none;
  color: rgb(57, 60, 65);
  font-size: 25px;
  text-align: center;
}

#tg-menu{
  text-decoration: none;
  color: rgb(57, 60, 65);;
  font-size: 25px;
  text-align: center;
  border-radius: 40% 40% 40% 40%;
  width: auto;
  margin-left: auto;
  margin-right: 0;
  height: 10px;
  font-family: 'Roboto';
}

.navigation-button{
  width: auto;
  max-width: 15%;
  height: 10px;
  border-style: none;
  backdrop-filter: blur(0px); 
  border-radius: 30% 30% 30% 30%;
  text-align: center;
  height: auto;
  border: none;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  

}
.navigation-button:hover{
 
  backdrop-filter: grayscale(30%);
  
}

 
@media (min-width:100px) and (max-width: 900px) and (max-height: 1200px)  {
  
  .primary-navigation{
    height: 80px;
  }
 
  
  /*  The Menu Button */
   .navigation-button{
    position: fixed;
    flex-direction: column;
    backdrop-filter: blur(0px); 
    border: none;
    top: 20px;
    right: 0;
    height: fit-content;
    text-align: center;
    cursor: pointer;
    width: auto;
   }
  
 

}


.flex{
  display: flex;
  gap: var(--gap, 5rem);
}

.mindai-section-site-header>.mindai-section-site-nav-items {
  min-block-size: 56px;
  position: static;
}
.mindai-section-site-header .mindai-section-align--center {
  -webkit-padding-end: var(--mindai-section-size--1x);
  -webkit-padding-start: var(--mindai-section-size--1x);
  justify-content: center;
  padding-inline-end: var(--mindai-section-size--1x);
  padding-inline-start: var(--mindai-section-size--1x);
}
@media (min-width: 1199px){
  #mindai-section-site-header-wrapper .mindai-section-site-header .mindai-section-align--start,
   #mindai-section-site-header-wrapper .mindai-section-site-header .mindai-section-align--end {
      min-inline-size: 240px;
  }
}
.mindai-section-site-header .mindai-section-align--end {
  -webkit-padding-end: var(--mindai-section-size--1x);
  justify-content: flex-end;
  padding-inline-end: var(--mindai-section-size--1x);
}
.mindai-section--product-name {
  white-space: nowrap;
}

.mindai-section-site-nav-items {
  align-items: center;
  display: flex;
  margin: 0;
  position: relative;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease,-webkit-transform .5s ease;
  z-index: 1;
}
.mindai-section-site-nav-items--vertical{
  align-items: normal;
  flex-direction: column;
}
dd, dl, dt, figcaption, figure, ol, ul {
  margin: 0;
  padding: 0;
}
#mindai-section-site-header-wrapper .mindai-section-site-nav-item {
  --mindai-section-nav-item--color: var(--mindai-section-theme-foreground-high-contrast);
}
.mindai-section-site-nav-item.mindai-section-animate--backdrop {
  -webkit-backdrop-filter: unset;
  backdrop-filter: unset;
  background-color: transparent;
}

 
.mindai-section-site-nav-item {
  --mindai-section-nav-item--padding: var(--mindai-section-size--1x);
  --mindai-section-nav-item--width: auto; 
  border-radius: 4px;
  color: var(--mindai-section-theme-foreground-high-contrast);
  display: inline-flex;
  font-weight: 500;
  inline-size: auto;
  min-block-size: 32px;
  padding: var(--mindai-section-size--half) var(--mindai-section-size--1x);
  transition: color .33s ease,background-color .33s ease;
}
.mindai-section-site-nav-item-text {
  color: var(--mindai-section-theme-foreground-high-contrast);
  margin: 0 var(--mindai-section-size--1x);
  transition: color .33s ease;
  font-size: 14px;
}
:is(h1,h2,h3,h4)+:is(p,ul,ol,.mindai-section-list,legend) {
  --mindai-section-spacer-top: calc(4px * 5);
}
@media (hover:hover){
  .mindai-section-site-nav-item:where(
    :not(.mindai-section-site-nav-item--read-only)
    ):hover{
      --mindai-section-nav-item--color:var(--mindai-section-theme-foreground-high-contrast);
      -webkit-backdrop-filter:blur(16px);
      backdrop-filter:blur(16px);
      background-color:rgba(0,0,0,.05);
    }.mindai-section-site-nav-item.mindai-section--highlighted:where(
        :not(.mindai-section-site-nav-item--read-only)
        :not(.mindai-section-animate--backdrop)
        ):hover{
          background-color:rgba(0,0,0,.075)
        }
  }
  

.site-nav-items{
  align-items: center;
  display: flex;
  margin: 0;
  position: relative;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease,-webkit-transform .5s ease;
  z-index: 1;
}

.site-nav-items--vertical {
  align-items: normal;
  flex-direction: column;
}

.site-nav-item {
  --nav-item--padding: 8px;
  --nav-item--width: auto;
  align-items: center;
  border-radius: 4px;
  color: #393c41;
  display: inline-flex;
  font-weight: 500;
  inline-size: auto;
  width: 90%;
  min-block-size: 42px;
  padding: 4px 8px;
  text-decoration:  none;
  transition: color .33s ease,background-color .33s ease;
}

.site-nav-item:hover{
  background-color:rgba(235, 224, 224, 0.75);
}
.card-body{
  padding-inline-start: 8px;
  -webkit-padding-start: 8px;
}