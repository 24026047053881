
.main-error{

  height: 100%;
  width: 100%;
  margin :auto;
  max-width: inherit;

}
 

@media (min-width:960px) {
  main{
    width: auto;
  }
}

.panel{
  height: 100%;
  width: 100%;
  top: 0;
  bottom:  0;
  left: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
}

.error-container{
  font-family: 'Roboto';
  font-weight: 300;
  padding: 150px 0;
  text-align: center;
  line-height: 1.25;
}

.error-container .error-code{
  font-size: 80px;
  margin-bottom: 10px;
}

.error-container .error-text{
  font-size: 60px;
  margin-bottom: 50px;
}