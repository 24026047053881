
.loader-full{ 
  background-color: #fff;
  z-index: 5;  
  left: 50%;
  top: 50%
}


.loader-container{
  width: 100%;  
  font-family: 'Roboto'; 
  text-align: center; 
  block-size: auto;
  border-radius: 4px;
  background-color: transparent;
  position: relative;  
  display: block; 
  border-width: 3px; 
  padding: 0px 0px ;
  justify-content: center;
  align-items: center;
  gap: 4px;  
}

.logo-loader{

  height: 40px;
  width: 40px; 
  fill: rgba(83, 150, 150, 0.791);
  
 }
.loader {
  
  border-top: 3px solid rgba(83, 150, 150, 0.791); /* Blue */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite; 
  background-color: transparent; 
  padding: 0px 0px ;
  justify-content: center;
  align-items: center; 
  left: 0;
  right: 0;
  bottom: 0;
  margin:auto; 
  margin-top: -50px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

 